import { Divider } from 'antd';
import { Gap } from '@/components/gap';
import { ReferenceAndInstructions } from './reference-and-instructions';
import { CFSShipmentItemList } from './shipment-item-list';
import { CFSShipmentDetailItem } from './shipment-detail-item';

export const CFSShipmentContent = () => {
	return (
		<div className='w-[710px] flex flex-col'>
			{/* title */}
			<Divider />

			<Gap height='24px' />

			<ReferenceAndInstructions />

			<Divider />

			<CFSShipmentItemList />

			<Divider />

			<CFSShipmentDetailItem type='pickup' />

			<Divider />

			<CFSShipmentDetailItem type='destination' />
		</div>
	);
};
