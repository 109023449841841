import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateCFSQuoteProps, UpdateCFSQuoteRes } from './interface';

export * from './interface';

export const updateCFSQuote = async (props: UpdateCFSQuoteProps) => {
	return await axios.put<UpdateCFSQuoteRes>(`/cfs/quotes/${props.path.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
