import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateCFSQuoteProps, CreateCFSQuoteRes } from './interface';

export * from './interface';

export const createCFSQuote = async (props: CreateCFSQuoteProps) => {
	return await axios.post<CreateCFSQuoteRes>(`/cfs/quotes`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
