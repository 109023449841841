import dayjs from 'dayjs';

export const generatePreYearMonthIndex = (nums: number) => {
	let current = dayjs();

	const array: { year: string; month: string }[] = [];

	for (let i = 0; i < nums; i++) {
		array.unshift({
			year: `${current.format('MMM')},${current.format('YYYY')}`,
			month: current.format('MMM'),
		});
		current = current.subtract(1, 'month');
	}

	return array;
};
