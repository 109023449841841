import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export interface CFSOrdersQuery {
	per_page?: number;
	page?: number;

	sort_by?: string;
	sort_value?: 'desc' | 'asc';
	last_days?: string;
	customer_status?: string[];
	query?: string;
	draft_shipment_id?: number;
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));

		valueTransfer(res, 'sort_by', searchParams.sort_by, (v) => v ?? '');
		valueTransfer(res, 'sort_value', searchParams.sort_value, (v) => v ?? 'desc');
		valueTransfer(res, 'last_days', searchParams.last_days, (v) => v ?? '30');
		valueTransfer(res, 'customer_status', searchParams.customer_status, (v) => v);
		valueTransfer(res, 'query', searchParams.query, (v) => v ?? '');
		valueTransfer(res, 'draft_shipment_id', searchParams.draft_shipment_id, (v) => v);
		return res as Partial<CFSOrdersQuery>;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		setSearchParams(qs.stringify({ ...parsedSearchParams, ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
