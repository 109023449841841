import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateCFSDraftShipmentProps, CreateCFSDraftShipmentRes } from './interface';

export * from './interface';

export const createCFSDraftShipment = async (props: CreateCFSDraftShipmentProps) => {
	return await axios.post<CreateCFSDraftShipmentRes>(`/cfs/draftShipments`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
