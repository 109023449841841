import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSShipmentProps, GetCFSShipmentRes } from './interface';

export * from './interface';

export const getCFSShipment = async (props: GetCFSShipmentProps) => {
	return await axios.get<GetCFSShipmentRes>(`/cfs/shipments/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
