import { useTranslation } from 'react-i18next';
import { useShipmentOrderContext } from '@/services/contexts';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import { formatePhoneNumber } from '@/utils/formate-phone-number';
import { Item } from '../item';
import { CFSShipment } from '@/interfaces/cfs-shipment.interface';

export const CFSShipmentDetailItem = ({ type }: { type: 'pickup' | 'destination' }) => {
	const { t } = useTranslation();

	const title = type === 'pickup' ? t(`Pickup`) : t(`Destination`);

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder as CFSShipment;

	const city = data[`${type}_city`];

	const company_name = data[`${type}_company_name`];
	const company_address = [
		data[`${type}_address1`],
		data[`${type}_address2`],
		city.name,
		`${city.state} ${data[`${type}_zipcode`]}`,
		city.country,
	]
		.filter(Boolean)
		.join(', ');
	const contact_name = data[`${type}_contact_name`];
	const contact_phone = formatePhoneNumber(data[`${type}_contact_phone`]);
	const contact_phone_ext = data[`${type}_contact_phone_ext`]
		? `${t('Ext')}.${data[`${type}_contact_phone_ext`]}`
		: '';
	const contact_email = data[`${type}_contact_email`];
	const date = formatMMDDYYYYDate(data[`estimated_${type}_date`]);
	const window =
		data[`${type}_open_time`] && data[`${type}_close_time`]
			? `${data[`${type}_open_time`] ?? ''} - ${data[`${type}_close_time`] ?? ''}`
			: '';
	const special_request = data[`${type}_special_request`];

	if (type === 'pickup') {
		return (
			<div className='flex flex-col gap-[24px]'>
				<div className='h4-b text-[#333333]'>{title}</div>

				<div className='flex flex-col gap-[16px]'>
					<div className='body-3-sb text-black'>
						<div>
							{data.pickup_cfs_location_firms_code} {company_name}
						</div>
						<div>{company_address}</div>
					</div>

					<Item title={t(`Special requirement`)} content={special_request} />
				</div>
			</div>
		);
	}

	return (
		<div className='flex flex-col gap-[24px]'>
			<div className='h4-b text-[#333333]'>{title}</div>

			<div className='flex flex-col gap-[16px]'>
				<div className='body-3-sb text-black'>
					<div>{company_name}</div>
					<div>{company_address}</div>
				</div>

				<Item title={t(`Facility contact name`)} content={contact_name} />

				<div className='grid grid-cols-2 grid-rows-[repeat(2,auto)] gap-x-[160px] gap-y-[16px]'>
					<Item title={t(`Contact phone`)} content={`${contact_phone} ${contact_phone_ext}`} />
					<Item title={t('Delivery Date')} content={date} />
					<Item title={t(`Email address`)} content={contact_email} />
					<Item title={t('Delivery window')} content={window} />
				</div>

				<Item title={t(`Special requirement`)} content={special_request} />
			</div>
		</div>
	);
};
