import { baseURL } from '..';
import qs from 'qs';
import { downloadFactory } from '../download-factory';
import { DownloadCFSShipmentsFileProps } from './interface';

export const downloadCFSShipmentsFile = (props: DownloadCFSShipmentsFileProps) => {
	const {
		fileName,
		path: { ids, columns, ...extra },
	} = props;
	const url =
		baseURL +
		`cfs/shipments/export?${qs.stringify({ ids, columns }, { arrayFormat: 'brackets' })}&${qs.stringify(extra, { arrayFormat: 'comma' })}`;

	return downloadFactory(url, fileName, { preferContentDisposition: true });
};
