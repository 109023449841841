import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSLocationsProps, GetCFSLocationsRes } from './interface';

export const getCFSLocations = async (props: GetCFSLocationsProps) => {
	return await axios.get<GetCFSLocationsRes>(`/cfs/locations/search`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
