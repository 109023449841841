import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSLocationProps, GetCFSLocationResponse } from './interface';

export const getCFSLocation = async (props: GetCFSLocationProps) => {
	return await axios.get<GetCFSLocationResponse>(`/cfs/locations/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
