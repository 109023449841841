import useShipmentOrderPath from '../../use-shipment-order-path';
import { FTLShipmentContent } from './ftl-shipment-content';
import { FTLSideShipmentInfo } from './ftl-side-shipment-info';
import { LTLShipmentContent } from './ltl-shipment-content';
import { LTLSideShipmentInfo } from './ltl-side-shipment-info';
import { CFSShipmentContent } from './cfs-shipment-content';
import { CFSSideShipmentInfo } from './cfs-side-shipment-info';

export const OrderInformation = () => {
	const { type } = useShipmentOrderPath();

	return (
		<div className='flex justify-between'>
			{/* content */}
			{type === 'ltl' ? (
				<LTLShipmentContent />
			) : type === 'cfs' ? (
				<CFSShipmentContent />
			) : (
				<FTLShipmentContent />
			)}

			{/* side shipment info */}
			{type === 'ltl' ? (
				<LTLSideShipmentInfo />
			) : type === 'cfs' ? (
				<CFSSideShipmentInfo />
			) : (
				<FTLSideShipmentInfo />
			)}
		</div>
	);
};
