export enum CFSAddressType {
	BUSINESS = 'business',
	RESIDENTIAL = 'residential',
	TRADESHOW = 'tradeshow',
	LIMITED_ACCESS = 'limitedAccess',
}

export const CFSNeededReportAddressTypeArr = [
	CFSAddressType.RESIDENTIAL,
	CFSAddressType.TRADESHOW,
	CFSAddressType.LIMITED_ACCESS,
];

export const CFSAddressTypeLabelMap = {
	[CFSAddressType.BUSINESS]: 'Business',
	[CFSAddressType.RESIDENTIAL]: 'Residential',
	// [CFSAddressType.TRADESHOW]: 'Trade Show',
	[CFSAddressType.LIMITED_ACCESS]: 'Limited Access',
};

export const CFSAddressTypeLabels = Object.values(CFSAddressTypeLabelMap);

export type CFSAddressTypeLabelType =
	(typeof CFSAddressTypeLabelMap)[keyof typeof CFSAddressTypeLabelMap];
