import { useCFSContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { useState } from 'react';

export const useServiceDetailModal = () => {
	const cfsContext = useCFSContext();

	const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

	const onClose = useMemoizedFn(() => {
		cfsContext.step = 4;
		close();
	});

	const [loading, setLoading] = useState(false);

	return {
		isOpen,
		open,
		close,
		onClose,
		loading,
		setLoading,
	};
};
