import { useTranslation } from 'react-i18next';
import { useCFSContext } from '@/services/contexts';
import { calcTotalWeight } from '@/services/contexts/cfs/cfs.context';
import { observer } from 'mobx-react-lite';
import { ItemTitle } from './item-title';

export const ShipmentInfoTotalWeight = observer(() => {
	const { t } = useTranslation();

	const cfsContext = useCFSContext();

	return (
		<ItemTitle
			content={t(`Total weight`)}
			value={`${calcTotalWeight(cfsContext.stepOneFormValues.items)} lbs`}
		/>
	);
});
