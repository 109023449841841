import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import {
	CreateCFSShipmentFromDraftShipmentProps,
	CreateCFSShipmentFromDraftShipmentRes,
	CreateCFSShipmentFromDraftShipmentParams,
} from './interface';

export * from './interface';

export const createCFSShipmentFromDraftShipment = async (
	props: CreateCFSShipmentFromDraftShipmentProps,
	params: CreateCFSShipmentFromDraftShipmentParams,
) => {
	return await axios.post<CreateCFSShipmentFromDraftShipmentRes>(
		`/cfs/draftShipments/${params.id}/toShipment`,
		props.data,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
