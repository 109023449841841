import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateCFSShipmentProps, UpdateCFSShipmentRes } from './interface';

export * from './interface';

export const updateCFSShipment = async (props: UpdateCFSShipmentProps) => {
	return await axios.put<UpdateCFSShipmentRes>(`/cfs/shipments/${props.path.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
