import { useTranslation } from 'react-i18next';
import { Button, Modal, message } from 'antd';
import { useCFSContext } from '@/services/contexts';
import { calcTotalVolume, calcTotalWeight } from '@/services/contexts/cfs/cfs.context';
import { observer } from 'mobx-react-lite';
import Balance from '@/components/balance/balance';
import { FT3 } from '@/components/ft3';
import { Gap } from '@/components/gap';
import { createCFSDraftShipment, CreateCFSDraftShipmentPropsData } from '@/services/apis';
import { extractNumber } from '@/utils/extract-number';
import dayjs from 'dayjs';
import { useState } from 'react';
import useCFSSearchRateParams from '../../use-cfs-search-rate-params';
import { clsx } from 'clsx';

const ItemContainer = (props: { item1: React.ReactNode; item2: React.ReactNode }) => (
	<div className='h-[57px] flex flex-col justify-between'>
		<div className='body-3-r'>{props.item1}</div>
		<div className='h4-b text-[#333333]'>{props.item2}</div>
	</div>
);

const Placeholder = () => <div className='h-[1.5px] w-[32px] bg-black'></div>;

export const Footer = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useCFSSearchRateParams();

	const cfsContext = useCFSContext();
	const isFinalStep = cfsContext.step !== 1 && cfsContext.step !== 2;
	const primaryText =
		cfsContext.step === 1
			? t(`Next`)
			: cfsContext.step === 2
				? t(`Select Carrier`)
				: t(`Place Order`);

	const [isSaveAsDraftModalOpen, setIsSaveAsDraftModalOpen] = useState(false);
	const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
	const handleSaveAsDraft = () => {
		setIsSaveAsDraftModalOpen(true);
	};
	const handleSaveAsDraftModalCancel = () => {
		setIsSaveAsDraftModalOpen(false);
	};
	const handleSaveAsDraftModalOk = () => {
		setIsSaveAsDraftLoading(true);
		const data = {
			id: parsedSearchParams.draft_shipment_id,
			pickup_cfs_location_id: cfsContext.pickupLocation.id, // required
			pickup_special_request: cfsContext.pickupSpecialRequest,
			destination_zipcode: cfsContext.destinationAddress.zipcode, // required
			destination_city_id: cfsContext.destinationAddress.city.id, // City id 从【获取城市列表】接口中获取 | required
			destination_state: cfsContext.destinationAddress.city.state,
			destination_company_name: cfsContext.destinationAddress.name, // Facility Name | required
			destination_contact_name: cfsContext.destinationAddress.contacts?.[0]?.name, // Facility Contact Name | required
			destination_contact_email: cfsContext.destinationAddress.contacts?.[0]?.email, // Contact Email | required
			destination_contact_phone: extractNumber(
				cfsContext.destinationAddress.contacts?.[0]?.phone ?? '',
			), // Contact Phone | required
			destination_contact_phone_ext: cfsContext.destinationAddress.contacts?.[0]?.phone_ext,
			destination_address1: cfsContext.destinationAddress.address, // Street Name | required
			destination_address2: cfsContext.destinationAddress.address2, // Apt, Suite | optional
			destination_special_request: cfsContext.destinationAddress.special_request,
			destination_address_type: cfsContext.destinationAddressType,

			estimated_pickup_date: dayjs(cfsContext.stepOneFormValues.pickup_date).format('YYYY-MM-DD'),

			...cfsContext.stepThreeFormValues,
			destination_open_time: cfsContext.stepThreeFormValues.destination_open_time
				? dayjs(cfsContext.stepThreeFormValues.destination_open_time).format('HH:mm')
				: undefined,
			destination_close_time: cfsContext.stepThreeFormValues.destination_close_time
				? dayjs(cfsContext.stepThreeFormValues.destination_close_time).format('HH:mm')
				: undefined,

			items: cfsContext.stepOneFormValues.items.map((item) => {
				const newItem = { ...item };
				delete item['id'];
				return newItem;
			}),

			pickup_accessorials: cfsContext.stepOneFormValues.pickup_accessorials ?? [],
			destination_accessorials: cfsContext.stepOneFormValues.destination_accessorials ?? [],
			shipment_accessorials: cfsContext.stepOneFormValues.shipment_accessorials,

			quote_id: cfsContext.quote?.id,
			rate_id: cfsContext.selectedQuoteRate.id,

			// insurance
			is_insurance_entrusted: cfsContext.stepThreeFormValues.is_insurance_entrusted,
			cargo_value: cfsContext.stepThreeFormValues.cargo_value,
		} as CreateCFSDraftShipmentPropsData;
		createCFSDraftShipment({ data })
			.then((response) => {
				message.success(t('Save draft success'));
				handleSaveAsDraftModalCancel();
				changeSearchParams({
					quote_id: response.data.data.quote_id,
					...parsedSearchParams,
					draft_shipment_id: response.data.data.id,
					rate_id: response.data.data.rate_id,
				});
			})
			.catch((error) => {
				message.error(error?.response?.data?.message ?? error.message);
			})
			.finally(() => {
				setIsSaveAsDraftLoading(false);
			});
	};

	return (
		<div className=' box-border w-full h-[130px] flex justify-between items-center'>
			<div className='grow shrink flex justify-between items-center'>
				<ItemContainer
					item1={t(`step n of m`, { n: cfsContext.step, m: 3 })}
					item2={<div className='h-[4px] w-[150px] bg-primary-regular rounded-[50px]'></div>}
				/>

				<ItemContainer
					item1={t(`Total weight`)}
					item2={<span>{`${calcTotalWeight(cfsContext.stepOneFormValues.items)} lbs`}</span>}
				/>

				<ItemContainer
					item1={t(`Total volume`)}
					item2={
						<span>
							{`${calcTotalVolume(cfsContext.stepOneFormValues.items)} `}
							<FT3 />
						</span>
					}
				/>

				<ItemContainer
					item1={t(`Total items`)}
					item2={`${cfsContext.stepOneFormValues.items.length} items`}
				/>

				<ItemContainer
					item1={
						<div className='flex items-baseline'>
							<div>{t(`Total price`)}</div>
							{cfsContext.step === 3 && cfsContext.stepThreeFormValues.is_insurance_entrusted && (
								<div>{t(`(includes premium: n usd)`, { n: cfsContext.premium })}</div>
							)}
						</div>
					}
					item2={
						cfsContext.selectedQuoteRate?.rate ? t(`$${cfsContext.totalPrice}`) : <Placeholder />
					}
				/>
			</div>

			<Gap width='100px' />

			<div className='grow-0 shrink-0 flex flex-col items-center'>
				<div className='flex items-center'>
					{isFinalStep && (
						<>
							<Button
								className='min-w-[150px] h-[48px] text-[18px] font-[600] mr-4'
								onClick={handleSaveAsDraft}
							>
								{t(`Save As Draft`)}
							</Button>
							<Modal
								open={isSaveAsDraftModalOpen}
								okButtonProps={{ loading: isSaveAsDraftLoading }}
								onCancel={handleSaveAsDraftModalCancel}
								onOk={handleSaveAsDraftModalOk}
							>
								<ul className='[&>li::marker]:text-primary-regular'>
									<li>
										<div className='h4-b text-primary-regular'>{t('Hints')}</div>
										<div>
											{t(
												'Please use “CFS / Order / See All Draft Orders” to view your saved orders.',
											)}
										</div>
									</li>
									<li>
										<div className='h4-b text-primary-regular'>{t('Attentions')}</div>
										<div>
											{t(
												'All quotes are valid for x hours. After the validity period, the quotes will no longer support editing and placing orders.',
												{ x: 4 },
											)}
										</div>
									</li>
								</ul>
							</Modal>
						</>
					)}
					<Button
						type='primary'
						className={clsx(
							isFinalStep ? 'min-w-[150px]' : 'min-w-[240px]',
							'h-[48px] text-[18px] font-[600] text-white',
						)}
						onClick={() => cfsContext.stepNextHandler()}
						{...cfsContext.stepNextButtonProps}
					>
						{primaryText}
					</Button>
					<Gap width='12px' />
					{/* <Button type='primary' ghost className='w-[160px] h-[48px] text-[18px] font-[600]'>
						{t(`Save as draft`)}
					</Button> */}
				</div>
				<Gap height='12px' />
				<div className='flex items-center'>
					<Balance>
						{(balance: number | string) => (
							<div className='body-3-r'>{t(`Balance`) + `: $${balance}`}</div>
						)}
					</Balance>
					{/* <div className='body-3-r'>{t(`Balance`) + `: $${cfsContext.balance}`}</div> */}
					{/* <Divider type='vertical' />
					<div className='body-3-r'>{t(`Wallet Balance: $n`, { n: 1000 })}</div> */}
				</div>
			</div>
		</div>
	);
});
