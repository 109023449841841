import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { MapBox } from '@/pages/search-rate/components/mapbox';

export const ShipmentInfoMap = observer(() => {
	const cfsContext = useCFSContext();

	return (
		<div className='w-full h-full bg-primary-dark-01'>
			<MapBox
				start={cfsContext.pickupLocation.city}
				to={cfsContext.destinationAddress.city}
				components={{ distance: true }}
			/>
		</div>
	);
});
