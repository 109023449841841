import { pickupAccesorials } from '@/interfaces/cfs-quote.interface';
import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';

export const ShipmentInfoPickupLocation = observer(() => {
	const cfsContext = useCFSContext();

	const pickupLocation = cfsContext.pickupLocation;

	const pickupAccessorials = cfsContext.stepOneFormValues.pickup_accessorials ?? [];

	return (
		<div className='flex flex-col gap-[4px] h-full'>
			<div className='h4-b'>
				{[pickupLocation.city?.full_name.split(',').slice(0, 2).join(','), pickupLocation.zipcode]
					.filter(Boolean)
					.join(', ')}
			</div>
			<div className='body-3-r'>{pickupLocation.firms_code}</div>
			<div className='body-3-r'>{pickupLocation.name}</div>
			<div className='body-3-r'>
				{pickupAccessorials
					.map((k) => pickupAccesorials.find((i) => i.key === k))
					.filter((i) => !!i)
					.map((i) => (
						<span>{i.name}</span>
					))}
			</div>
		</div>
	);
});
