import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {
	GetCFSAutofillFromPalletCalculatorRes,
	GetCFSAutofillProps,
	GetCFSAutofillRes,
} from './interface';

export * from './interface';

const BASE_URL = import.meta.env.VITE_DRAYAI_API_BASE_URL;
const FAST_API_BASE_URL = import.meta.env.VITE_DRAYAI_API_FAST_API_BASE_URL;
const SECRET_KEY = import.meta.env.VITE_AUTH_SECRET_KEY;

// 定义各个接口的URL
const URL = `${FAST_API_BASE_URL}/autofill_for_cfs_quote`;
const URL_FOR_SHIPMENT = `${FAST_API_BASE_URL}/autofill_for_cfs_shipment`;
const URL_FAST_API_FROM_PALLET_CALCULATOR = `${FAST_API_BASE_URL}/ai/pallet/calculator/extract`;

// 新增的校验函数
const generateAuthData = (content: string) => {
	const timestamp = Math.floor(Date.now() / 1000).toString();
	const message = `${content}${timestamp}`;
	const signature = CryptoJS.HmacSHA256(message, SECRET_KEY).toString();
	return { timestamp, signature };
};

// 修改后的 getCFSAIAutofill 函数
export const getCFSAIAutofill = async (props: GetCFSAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	const response = await axios.post<GetCFSAutofillRes>(
		URL,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);

	console.log('getCFSAIAutofill response', response);

	return response;
};

export const getCFSAIAutofillForShipment = async (props: GetCFSAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	const response = await axios.post<GetCFSAutofillRes>(
		URL_FOR_SHIPMENT,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);

	console.log('getCFSAIAutofillForShipment response', response);

	return response;
};

export const getCFSAIAutofillFromPalletCalculator = async (props: GetCFSAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	const response = await axios.post<GetCFSAutofillFromPalletCalculatorRes>(
		URL_FAST_API_FROM_PALLET_CALCULATOR,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);

	console.log('getCFSAIAutofillFromPalletCalculator response', response);

	return response;
};
