import { apiHooks } from '@/hooks/apis';
import useShipmentOrderPath from './use-shipment-order-path';

export const useShipmentOrder = (props?: Record<string, any>) => {
	const { type, id } = useShipmentOrderPath();

	if (type === 'ltl') {
		return apiHooks.useGetShipment({
			...(props ?? {}),
			defaultParams: [{ path: { id } }],
			cacheKey: `get-ltl-shipment-${id}`,
		});
	}

	if (type === 'ftl') {
		return apiHooks.useGetFTLShipment({
			...(props ?? {}),
			defaultParams: [{ path: { id } }],
			cacheKey: `get-ftl-shipment-${id}`,
		});
	}

	if (type === 'cfs') {
		return apiHooks.useGetCFSShipment({
			...(props ?? {}),
			defaultParams: [{ path: { id } }],
			cacheKey: `get-cfs-shipment-${id}`,
		});
	}
};
