import { CFSAddressType } from '@/interfaces/cfs-address-type.enum';
import { useCFSContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import { apiHooks } from '@/hooks/apis';
import { useIsPickupDateDisabled } from '@/hooks/use-is-pickup-date-disabled';
import { LaDayjs } from '@/utils/la-dayjs';
import { useGenerateAddress } from '../../use-generate-address';
import { getCFSLocations } from '@/services/apis';
import { message } from 'antd';

interface AutofillData {
	pickup_location_firmscode: string;
	destination_city_name: string;
	destination_state_name: string;
	destination_zipcode: string;
	destination_address1?: string;
	destination_address2?: string;
	destination_address_type: string;
	estimated_pickup_date?: string;
	items?: any[];
	pickup_accessorials?: readonly (CFSAddressType | 'liftgate' | 'inside')[];
	destination_accessorials?: readonly (CFSAddressType | 'liftgate' | 'inside' | 'appointment')[];

	shipment_accessorials?: string[];
}

export const useCFSStepOneAIAutofill = () => {
	const cfsContext = useCFSContext();
	const { runAsync } = apiHooks.useGetCFSAutofill();
	const { generateAddress } = useGenerateAddress();

	const { isPickupDateDisabled } = useIsPickupDateDisabled({ whiteListType: 'cfs' });

	const mapAddressTypeToEnum = (addressType: string): CFSAddressType => {
		switch (addressType?.toLowerCase()) {
			case 'residential':
				return CFSAddressType.RESIDENTIAL;
			case 'limitedaccess':
				return CFSAddressType.LIMITED_ACCESS;
			case 'tradeshow':
				return CFSAddressType.TRADESHOW;
			case 'business':
			default:
				return CFSAddressType.BUSINESS;
		}
	};

	const processAutofillData = async (data: AutofillData) => {
		try {
			// 检查必要字段
			const requiredFields = [
				'pickup_location_firmscode',
				'destination_city_name',
				'destination_state_name',
				'destination_zipcode',
			] as const;

			const missingFields = requiredFields.filter((field) => !data[field]);
			if (missingFields.length > 0) {
				console.error('Missing required fields:', missingFields);
				throw new Error('Failed to extract required information');
			}

			const pickupLocations = await getCFSLocations({
				params: { name: data.pickup_location_firmscode, limit: 1 },
			});
			const pickupLocation = pickupLocations.data.data[0];

			const destinationAddress = await generateAddress({
				city_name: data.destination_city_name,
				state_name: data.destination_state_name,
				zipcode: data.destination_zipcode,
				address1: data.destination_address1,
				address2: data.destination_address2,
			});

			cfsContext.pickupLocation = pickupLocation;

			if (destinationAddress) {
				cfsContext.destinationAddress = destinationAddress;
			}

			cfsContext.destinationAddressType = mapAddressTypeToEnum(data.destination_address_type);

			cfsContext.stepOneFormValues = {
				pickup_cfs_location_id: pickupLocation?.id,
				pickup_date:
					!!data.estimated_pickup_date && !isPickupDateDisabled(LaDayjs(data.estimated_pickup_date))
						? LaDayjs(data.estimated_pickup_date)
						: undefined,
				items: (data.items || []).map((item) => ({ ...item, id: Math.random().toString() })),
				pickup_accessorials: (data.pickup_accessorials || []) as (
					| CFSAddressType
					| 'reimbursed_cfs_costs'
				)[],
				destination_accessorials: (data.destination_accessorials || []) as (
					| CFSAddressType
					| 'liftgate'
					| 'inside'
				)[],
				shipment_accessorials: (data.shipment_accessorials || []).filter(
					(acc) => acc === 'hazmat',
				) as ['hazmat'],
			};

			cfsContext.calcItemFreightClass();
		} catch (error) {
			message.error(error?.message || error);
		}
	};

	const handleAIAutofill = useMemoizedFn(async (content: string) => {
		try {
			const response = await runAsync({ data: { content } });
			// 数据解析方式
			let data: AutofillData;

			if (typeof response.data === 'string') {
				data = JSON.parse(response.data) as AutofillData;
			} else if (response.data && typeof response.data === 'object') {
				data = response.data as AutofillData;
			} else {
				throw new Error('Invalid response format');
			}

			await processAutofillData(data);
		} catch (err) {
			console.error('Text autofill error:', err);
			throw err;
		}
	});

	const handleAIAutofillFromImage = useMemoizedFn(async (parsedData: any) => {
		try {
			let data: AutofillData;

			if (typeof parsedData === 'string') {
				data = JSON.parse(parsedData) as AutofillData;
			} else if (parsedData && typeof parsedData === 'object') {
				data = parsedData as AutofillData;
			} else {
				throw new Error('Invalid response format');
			}

			await processAutofillData(data);
		} catch (err) {
			console.error('Image autofill error:', err);
			throw err;
		}
	});

	return { handleAIAutofill, handleAIAutofillFromImage };
};
