import { useCFSContext } from '@/services/contexts/cfs';
import { Modal, Table, Input, message, TableProps, Typography, InputRef } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { getCFSLocations } from '@/services/apis';
import { CFSLocation } from '@/interfaces/cfs-location.interface';

const CFSCodeSelect = ({ errored }: { errored: boolean }) => {
	const { t } = useTranslation();

	const cfsContext = useCFSContext();
	const pickupCFSLocationId = cfsContext.pickupLocation?.id;

	const Placeholder = () => {
		if (!pickupCFSLocationId) {
			return (
				<span className='text-[rgba(0,0,0,0.25)]'>{`${t('CFS Code')}/${t('Facility Name')}/${t('Zipcode')}`}</span>
			);
		}
		return (
			<div className='text-[rgba(0,0,0,0.88)]'>
				<div>
					{[cfsContext.pickupLocation.firms_code, cfsContext.pickupLocation.name]
						.filter(Boolean)
						.join(', ')}
				</div>
				<div>
					{[
						cfsContext.pickupLocation.address1,
						cfsContext.pickupLocation.address2,
						cfsContext.pickupLocation.city?.name,
						cfsContext.pickupLocation.city?.state,
						cfsContext.pickupLocation.zipcode,
					]
						.filter(Boolean)
						.join(', ')}
				</div>
			</div>
		);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = () => {
		setIsModalOpen(true);
	};
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState<CFSLocation[]>([]);
	const handleSearch = (value: string) => {
		if (!value) {
			return;
		}
		setIsLoading(true);
		getCFSLocations({
			params: {
				name: value,
				limit: 50,
			},
		})
			.then((response) => {
				setTableData(response.data.data);
			})
			.catch((error) => {
				console.error(error);
				message.error(t('Failed to search CFS locations'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSelectCFSLocation = (record: CFSLocation) => {
		cfsContext.pickupLocation = record;
		handleCloseModal();
	};

	const tableColumns: TableProps<CFSLocation>['columns'] = [
		{
			title: t('FIRMS Code'),
			key: 'firms_code',
			dataIndex: 'firms_code',
			width: 120,
		},
		{
			title: t('Facility Name'),
			key: 'name',
			dataIndex: 'name',
			width: 200,
		},
		{
			title: t('Type'),
			key: 'facility_type_label',
			dataIndex: 'facility_type_label',
			width: 160,
		},
		{
			title: t('Address'),
			key: 'address',
			dataIndex: 'address',
			render: (_, record) => [record.address1, record.address2].filter(Boolean).join(' '),
		},
		{
			title: t('City'),
			key: 'city',
			dataIndex: 'city',
			render: (_, record) => record.city.name,
			width: 180,
		},
		{
			title: t('State'),
			key: 'state',
			dataIndex: 'state',
			render: (_, record) => record.city.state,
			width: 80,
		},
		{
			title: t('Zipcode'),
			key: 'zipcode',
			dataIndex: 'zipcode',
			width: 88,
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 100,
			render: (_, record) => (
				<Typography.Link
					className='p-0 !cursor-pointer'
					onClick={() => handleSelectCFSLocation(record)}
				>
					{t('Select')}
				</Typography.Link>
			),
		},
	];

	const inputSearchRef = useRef<InputRef>(null);
	useEffect(() => {
		if (isModalOpen) {
			setTimeout(() => {
				inputSearchRef.current?.focus();
			});
		}
	}, [isModalOpen]);

	return (
		<>
			<div
				className={clsx(
					'w-[510px] h-[42px] bg-white rounded-[4px] border-[1px] border-solid box-border cursor-pointer px-[11px] flex justify-center flex-col',
					errored ? 'border-[#ff4d4f]' : 'border-grey-04',
				)}
				onClick={handleOpenModal}
			>
				<Placeholder />
			</div>
			<Modal
				width={1200}
				open={isModalOpen}
				footer={null}
				title={t('CFS Address Book')}
				onCancel={handleCloseModal}
				centered
			>
				<Input.Search
					ref={inputSearchRef}
					allowClear
					enterButton
					placeholder={t('Please input FIRMS code')}
					loading={isLoading}
					onSearch={handleSearch}
				/>
				<Table<CFSLocation>
					dataSource={tableData}
					columns={tableColumns}
					className='mt-2'
					pagination={false}
					scroll={{ y: 512 }}
				/>
			</Modal>
		</>
	);
};

export { CFSCodeSelect };
