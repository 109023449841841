import { useLocation, useParams } from 'react-router-dom';

export interface ShipmentOrderPath {
	type: 'ltl' | 'ftl' | 'cfs';
	id: string;
}

export default () => {
	const { orderId } = useParams();

	const location = useLocation();

	const type = location.pathname.includes('ltl')
		? 'ltl'
		: location.pathname.includes('ftl')
			? 'ftl'
			: 'cfs';

	return {
		id: orderId,
		type,
	};
};
