import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { OrderFile } from '@/interfaces/file.interface';
import { FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { downloadFtlDefaultBOLFile, downloadFtlFile, downloadCFSFile } from '@/services/apis';
import { downloadLtlFile } from '@/services/apis/download-ltl-file';
import { useShipmentOrderContext } from '@/services/contexts';
import { DownloadOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { FileIcon } from '@/components/file-icon';
import { Table, TableProps } from '@/components/table';
import useShipmentOrderPath from '../../use-shipment-order-path';

export const Documents = () => {
	const { t } = useTranslation();

	const { type } = useShipmentOrderPath();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const documents =
		type === 'ltl' || type === 'cfs'
			? (data.documents ?? [])
			: [
					...(data.documents ?? []),
					{
						id: -1,
						file_name: (data as FTLShipment).default_bol_file_name,
						custom_properties: { document_type: 'BOL' },
						size: '',
						created_at: '',
					},
				];

	const downloadDoc =
		type === 'ltl' ? downloadLtlFile : type === 'cfs' ? downloadCFSFile : downloadFtlFile;

	const columns: TableProps<OrderFile>['columns'] = [
		{
			title: t(`Name`),
			dataIndex: 'file_name',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={clsx(className, 'flex items-center gap-[10px]')}>
					<FileIcon type={value.split('.').at(-1)} />
					<span className='body-4-m text-black font-[500]'>{value}</span>
				</div>
			),
		},
		{
			title: t(`Category`),
			dataIndex: ['custom_properties', 'document_type'],
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					{value}
				</div>
			),
		},
		{
			title: t(`Size`),
			width: '130px',
			dataIndex: 'size',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>{`${value}`}</div>
			),
		},
		{
			title: t(`Upload Time`),
			width: '130px',
			dataIndex: 'created_at',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>{`${value}`}</div>
			),
		},
		{
			title: '',
			type: 'extra',
			width: '100px',
			dataIndex: '',
			CellRender: ({ value, record, style, className }) => (
				<div style={style} className={className}>
					<Button
						type='text'
						icon={<DownloadOutlined />}
						onClick={() =>
							record.id === -1
								? downloadFtlDefaultBOLFile(
										{ shipmentId: data.id, fileName: (data as FTLShipment).default_bol_file_name },
										{
											preview: true,
										},
									)
								: downloadDoc(
										{ documentId: record.id, shipmentId: data.id, fileName: record.name },
										// { preview: true },
									)
						}
					/>
				</div>
			),
		},
	];

	return (
		<div>
			<Table
				dataSource={documents}
				columns={columns}
				commonHeaderClass='h-[32px] flex items-center body-4-b text-grey-01  bg-grey-05'
				commonCellClass='px-[16px]'
				commonBodyClass='h-[60px] flex items-center body-4-r text-grey-01 font-[400]'
				commonCellStyle={{ borderBottom: '1px solid #E6E6E6' }}
			/>
		</div>
	);
};
