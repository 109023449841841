import { getCFSDraftShipments } from '@/services/apis';
import { GetCFSDraftShipmentsProps } from '@/services/apis/get-cfs-draft-shipments/interface';
import { usePagination } from 'ahooks';
import { message } from '@/utils/message';

export const useGetCFSDraftShipments = (options?: Record<any, any>) => {
	const res = usePagination(
		async ({ current, pageSize }, props: GetCFSDraftShipmentsProps) => {
			if (!props) {
				return {
					list: [],
					total: 0,
				};
			}

			const response = await getCFSDraftShipments({
				params: {
					...props.params,
					page: current,
					per_page: pageSize,
				},
			});

			const list = response?.data?.data ?? [];

			if (list.length === 0) {
				return {
					list,
					total: response?.data?.meta?.total,
				};
			}

			return {
				list: list,
				total: response?.data?.meta?.total,
			};
		},
		{
			onError: (error) => {
				// @ts-expect-error no response type in error
				message.error(`${error.response?.data?.message ?? error.message}`);
			},
			defaultCurrent: 1,
			defaultPageSize: 10,
			...options,
		},
	);

	return {
		...res,
		data: res.data?.list ?? [],
	};
};
