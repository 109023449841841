import { Checkbox, Form, Modal, ModalProps, Radio } from 'antd';
import { ExportModalProps } from './types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { downloadCFSShipmentsFile } from '@/services/apis';
import { DownloadCFSShipmentsFileProps } from '@/services/apis/download-cfs-shipments-file/interface';
import dayjs from 'dayjs';

const initialValues = {
	type: undefined,
	columns: [
		'Order Create Date',
		'Order Number',
		'Customer Reference Number',
		'Customer Name',
		'Customer Email',
		'Agent Name',
		'Pickup Address',
		'Destination Address',
		'Base Price',
		'Premium',
	],
};

export const ExportModal = ({ selected, total, queries, ...props }: ExportModalProps) => {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	// 因为页面上的 columns 和可选的 columns 有所不同，所以我们不能直接使用页面上的 columns
	// 在 modal 内部进行构建
	const checkboxGroupOptions = [
		{ label: t('Order Create Date'), value: 'Order Create Date' },
		{ label: t('Order Number'), value: 'Order Number' },
		{ label: t('Customer Reference Number'), value: 'Customer Reference Number' },
		{ label: t('Customer Name'), value: 'Customer Name' },
		{ label: t('Customer Email'), value: 'Customer Email' },
		{ label: t('Agent Name'), value: 'Agent Name' },
		{ label: t('Pickup Address'), value: 'Pickup Address' },
		{ label: t('Destination Address'), value: 'Destination Address' },
		{ label: t('Base Price'), value: 'Base Price' },
		{ label: t('Premium'), value: 'Premium' },
	];

	const [isLoading, setIsLoading] = useState(false);
	// Hack
	const handleOk: ModalProps['onOk'] = (e) => {
		form
			// 使用的是 modal 自带的 okButton，手动调用 Form 校验
			.validateFields()
			.then((values) => {
				setIsLoading(true);
				// 构建最终请求参数
				const params: DownloadCFSShipmentsFileProps = {
					fileName: `cfs_shipment_${dayjs().format('YYYY-MM-DD')}.xlsx`,
					path: {
						columns: values.columns,
					},
				};
				if (values.type === 'selected') {
					params.path.ids = selected.map((item) => item.id);
				} else {
					params.path = { ...params.path, ...queries };
				}
				downloadCFSShipmentsFile(params)
					.then(() => {
						// 调用传入的 onOk 方法
						props.onOk?.(e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch(() => {});
	};

	return (
		<Modal
			width={720}
			title={
				<div className='flex items-end gap-4'>
					{t(`Export`)}
					<span className='body-3-m text-grey-01 text-sm font-normal'>
						{t(`Note: The export limit is 500`)}
					</span>
				</div>
			}
			{...props}
			okButtonProps={{
				...props.okButtonProps,
				loading: isLoading || props.okButtonProps?.loading,
			}}
			cancelButtonProps={{
				...props.cancelButtonProps,
				disabled: isLoading || props.cancelButtonProps?.disabled,
			}}
			onOk={handleOk}
		>
			<Form form={form} colon={false} initialValues={initialValues} disabled={isLoading}>
				<Form.Item
					name='type'
					label={t(`Export Records`)}
					rules={[
						{
							required: true,
							message: t(`Please select export type type`),
						},
					]}
				>
					<Radio.Group
						className='mt-1 flex flex-col'
						options={[
							{
								label: t(`Export selected records, x in total`, { x: selected.length }),
								value: 'selected',
								disabled: selected.length === 0,
							},
							{
								label: t(`Export all records that meet the filter, x in total`, { x: total }),
								value: 'all',
								disabled: total > 500,
							},
						]}
					></Radio.Group>
				</Form.Item>
				<Form.Item
					label={t(`Export Column Configuration`)}
					labelCol={{ span: 24 }} // antd 5.15 未支持 layout='vertical'，hack 处理
					name='columns'
					rules={[
						{
							required: true,
							type: 'array',
							min: 1,
							message: t(`Please select export columns`),
						},
					]}
				>
					<Checkbox.Group className='mt-1' options={checkboxGroupOptions} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
