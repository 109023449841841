import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSQuoteListProps, GetCFSQuoteListRes } from './interface';

export * from './interface';

export const getCFSQuoteList = async (props: GetCFSQuoteListProps) => {
	return await axios.get<GetCFSQuoteListRes>(`/cfs/quotes`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
