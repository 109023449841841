import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { ItemTitle } from './item-title';

export const ShipmentInfoAccessorials = observer(() => {
	const { t } = useTranslation();

	const cfsContext = useCFSContext();

	const accessorials = [
		cfsContext.stepOneFormValues.destination_accessorials,
		cfsContext.stepOneFormValues.pickup_accessorials,
		cfsContext.stepOneFormValues.shipment_accessorials,
	]
		.filter((arr) => (arr ?? []).length > 0)
		.join(',');

	return (
		<ItemTitle
			content={t(`Accessorials`)}
			value={<Typography.Text ellipsis>{accessorials}</Typography.Text>}
		/>
	);
});
