import { useTranslation } from 'react-i18next';
import { Button, Input, message, Modal, Spin, Tabs } from 'antd';
import Shining10pxIcon from '@/icons/shining-10px-icon';
import { useBoolean, useMemoizedFn, useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';

export const AIAutofillBtn = ({
	handleAIAutofill,
	handleAIAutofillFromImage,
}: {
	handleAIAutofill: (content: string) => void | Promise<void>;
	handleAIAutofillFromImage?: (data: any) => void | Promise<void>;
}) => {
	const { t } = useTranslation();

	const [content, setContent] = useSafeState('');

	const [openingModal, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(false);
	const [activeTab, setActiveTab] = useSafeState<'text' | 'image'>('text');
	const [isProcessing, setIsProcessing] = useSafeState(false);

	const handleContentKeydown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			if (!content) {
				setContent(t('CFS_AUTOFILL_PLACEHOLDER_TEXT'));
				setActiveTab('text');
			}
		}
	};

	const _handleAIAutofill = useMemoizedFn(async () => {
		if (isProcessing) {
			return;
		}

		try {
			setIsProcessing(true);
			startLoading();
			await handleAIAutofill(content);
			closeModal();
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
			setIsProcessing(false);
		}
	});

	const handleImageUploadSuccess = async (parsedData: any) => {
		if (!handleAIAutofillFromImage || isProcessing) {
			return;
		}

		try {
			setIsProcessing(true);
			startLoading();
			await handleAIAutofillFromImage(parsedData);
			closeModal();
		} catch (err) {
			console.error('Image processing error:', err);
			message.error(t('Failed to process image data'));
		} finally {
			endLoading();
			setIsProcessing(false);
		}
	};

	const handleModalClose = () => {
		if (!isProcessing) {
			closeModal();
		}
	};

	const items = [
		{
			key: 'text',
			label: t('Text Input'),
			children: (
				<div className='mt-[24px] relative'>
					<Input.TextArea
						rows={16}
						placeholder={t('CFS_AUTOFILL_PLACEHOLDER_TEXT')}
						value={content}
						onKeyDown={handleContentKeydown}
						onChange={(e) => setContent(e.target.value)}
						disabled={isProcessing}
					/>
					{isProcessing && (
						<div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-80'>
							<Spin tip={t('Processing...')} />
						</div>
					)}
				</div>
			),
		},
	];

	const renderContent = () => {
		return handleAIAutofillFromImage ? (
			<Tabs
				activeKey={activeTab}
				onChange={(key) => setActiveTab(key as 'text' | 'image')}
				items={items}
			/>
		) : (
			items[0].children
		);
	};

	return (
		<>
			<Button
				style={{ backgroundColor: '#6B33FE', border: 'unset' }}
				className='flex gap-[4px] items-center'
				onClick={openModal}
			>
				<Shining10pxIcon />
				<span className='body-4-m text-white'>AI {t(`Autofill`)}</span>
			</Button>

			<Modal
				open={openingModal}
				title={t(`Autofill`) + ' ' + t(`Information`)}
				okText={t(`Confirm`)}
				cancelText={t(`Cancel`)}
				closable
				confirmLoading={isProcessing}
				okButtonProps={{
					className: 'p-[5px_20px] h-fit body-3-sb text-white',
					style: {
						display: activeTab === 'image' ? 'none' : 'inline-block',
					},
					disabled: isProcessing || !content.trim(),
				}}
				cancelButtonProps={{
					className: 'p-[5px_20px] h-fit body-3-sb',
					disabled: isProcessing,
				}}
				onCancel={handleModalClose}
				onOk={_handleAIAutofill}
				maskClosable={!isProcessing}
			>
				{renderContent()}
				<Gap height='12px' />
				<div className='foot-note-1-r text-grey-01'>
					<div>{t(`Disclaimer`)}:</div>
					<div>
						{t(
							`Please double-check all entries to ensure accuracy before finalizing any documents or submissions.`,
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};
