import { useLTLContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { useGenerateAddress } from '../../use-generate-address';

export const useLTLStepThreeAIAutofill = () => {
	const ltlContext = useLTLContext();

	const { runAsync: getLTLAIAutofillForShipment } = apiHooks.useGetLTLAutofillForShipment();
	const { generateAddress } = useGenerateAddress();

	const handleAIAutofill = useMemoizedFn(async (content: string) => {
		try {
			const res = await getLTLAIAutofillForShipment({ data: { content } });
			const data = res.data;

			// 更新 pickupAddress
			if (ltlContext.pickupAddress) {
				ltlContext.pickupAddress = {
					...ltlContext.pickupAddress,
					address: data.pickup_address1 || ltlContext.pickupAddress.address,
					address2: data.pickup_address2 || ltlContext.pickupAddress.address2,
					// zipcode: data.pickup_zipcode || ltlContext.pickupAddress.zipcode,
					name: data.pickup_company_name || ltlContext.pickupAddress.name,
					contacts: ltlContext.pickupAddress.contacts.map((contact, index) => ({
						...contact,
						name: index === 0 ? data.pickup_contact_name || contact.name : contact.name,
						phone: index === 0 ? data.pickup_contact_phone || contact.phone : contact.phone,
						email: index === 0 ? data.pickup_contact_email || contact.email : contact.email,
					})),
					special_request: data.pickup_special_request || ltlContext.pickupAddress.special_request,
				};
			}

			// 更新 destinationAddress
			if (ltlContext.destinationAddress) {
				ltlContext.destinationAddress = {
					...ltlContext.destinationAddress,
					address: data.destination_address1 || ltlContext.destinationAddress.address,
					address2: data.destination_address2 || ltlContext.destinationAddress.address2,
					// zipcode: data.destination_zipcode || ltlContext.destinationAddress.zipcode,
					name: data.destination_company_name || ltlContext.destinationAddress.name,
					contacts: ltlContext.destinationAddress.contacts.map((contact, index) => ({
						...contact,
						name: index === 0 ? data.destination_contact_name || contact.name : contact.name,
						phone: index === 0 ? data.destination_contact_phone || contact.phone : contact.phone,
						email: index === 0 ? data.destination_contact_email || contact.email : contact.email,
					})),
					special_request:
						data.destination_special_request || ltlContext.destinationAddress.special_request,
				};
			}

			// 更新 stepThreeFormValues
			ltlContext.stepThreeFormValues = {
				...ltlContext.stepThreeFormValues,
				customer_reference_number:
					data.customer_reference_number ||
					ltlContext.stepThreeFormValues.customer_reference_number,
				pickup_number: data.pickup_number || ltlContext.stepThreeFormValues.pickup_number,
				dropoff_number: data.dropoff_number || ltlContext.stepThreeFormValues.dropoff_number,
				pickup_open_time: data.pickup_open_time
					? dayjs(data.pickup_open_time, 'HH:mm')
					: ltlContext.stepThreeFormValues.pickup_open_time,
				pickup_close_time: data.pickup_close_time
					? dayjs(data.pickup_close_time, 'HH:mm')
					: ltlContext.stepThreeFormValues.pickup_close_time,
				destination_open_time: data.destination_open_time
					? dayjs(data.destination_open_time, 'HH:mm')
					: ltlContext.stepThreeFormValues.destination_open_time,
				destination_close_time: data.destination_close_time
					? dayjs(data.destination_close_time, 'HH:mm')
					: ltlContext.stepThreeFormValues.destination_close_time,
			};
		} catch (error) {
			console.error('Error in handleAIAutofill:', error);
			// 这里可以添加错误处理逻辑，比如���示一个错误提示
		}
	});

	return { handleAIAutofill };
};
