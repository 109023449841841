import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiHooks } from '@/hooks/apis';
import { Button, Drawer, Pagination, Empty, Table, TableProps, message, Tooltip } from 'antd';
import CenterSpin from '@/components/center-spin';
import {
	calcTotalVolume,
	calcTotalWeight,
	generateNumberOfUnits,
} from '@/services/contexts/cfs/cfs.context';
import { FT3 } from '@/components/ft3';
import { Quote } from '@/interfaces/cfs-quote.interface';
import { useMount, useTimeout } from 'ahooks';
import useCFSOrdersParams from '../../use-cfs-orders-params';
import { CFSShipment } from '@/interfaces/cfs-shipment.interface';
import { LaDayjs } from '@/utils/la-dayjs';
import dayjs from 'dayjs';
import calcPremium from '@/utils/calc-premium';

const getIsExpired = (draftShipment: CFSShipment) =>
	dayjs().isSameOrAfter(
		LaDayjs(draftShipment.create_date, 'MM/DD/YYYY HH:mm').add(4, 'hours').tz(dayjs.tz.guess()),
		'minutes',
	);

export const ViewDraftOrdersButton = () => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useCFSOrdersParams();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const handleOpenDrawer = () => setIsDrawerOpen(true);
	const handleCloseDrawer = () => setIsDrawerOpen(false);

	const {
		runAsync: getDraftShipments,
		data: draftShipments,
		loading: isLoading,
		pagination,
	} = apiHooks.useGetCFSDraftShipments();
	const { current, total, pageSize, onChange: handleChangePagination } = pagination;

	const handleEditDraftShipment = (draftShipment: CFSShipment) => () => {
		const isExpired = getIsExpired(draftShipment);
		if (!isExpired) {
			changeSearchParams({
				...parsedSearchParams,
				draft_shipment_id: draftShipment.id,
				quote_id: draftShipment.quote_id,
				rate_id: draftShipment.rate_id,
			});
		} else {
			message.error(t('The rate for this draft has expired. Not editable.'));
		}
	};

	const columns: TableProps<CFSShipment>['columns'] = [
		{
			title: t('Quote#'),
			dataIndex: ['quote_number'],
			key: 'quote_number',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('Origin'),
			dataIndex: ['pickup_zipcode'],
			key: 'pickup_zipcode',
			width: 200,
			render: (_, record) => (
				<>
					<div>{record.pickup_zipcode}</div>
					<div>
						{[record.pickup_city.name, record.pickup_city.state].filter(Boolean).join(', ')}
					</div>
					<div>{record.pickup_cfs_location_firms_code}</div>
				</>
			),
		},
		{
			title: t('Destination'),
			dataIndex: ['destination_zipcode'],
			key: 'destination_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					{record.destination_zipcode && <div>{record.destination_zipcode}</div>}
					<div>
						<span>{record.destination_city.name ?? ''}</span>
						{record.destination_city.name && record.destination_city.state && <span>{`, `}</span>}
						<span>{record.destination_city.state}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Pallets/Volume/Weight'),
			dataIndex: ['items'],
			key: 'items',
			width: 200,
			render: (items: Quote['items']) => (
				<div>
					<div>{`${generateNumberOfUnits(items)}`}</div>
					<div>
						<span>{`${calcTotalVolume(items)} `}</span>
						<FT3 />
						<span>{`${calcTotalWeight(items)} lbs`}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Carrier'),
			dataIndex: ['vendor_carrier_name'],
			key: 'vendor_carrier_name',
			width: 120,
		},
		{
			title: t('Base Price'),
			dataIndex: 'sell_rate',
			width: 120,
			render: (value) => `$${value}`,
		},
		{
			title: t('Premium'),
			dataIndex: 'premium',
			width: 120,
			render: (value, record) => `$${calcPremium(Number(record.cargo_value)) ?? 0}`,
		},
		{
			title: t('Remaining Valid Time'),
			dataIndex: 'create_date',
			width: 140,
			render: (value, record) => {
				if (getIsExpired(record)) {
					return t('Expired');
				}
				const diff = LaDayjs(value, 'MM/DD/YYYY HH:mm')
					.add(4, 'hours')
					.tz(dayjs.tz.guess())
					.diff(dayjs().millisecond(0).second(0), 'minutes');
				if (diff / 60 >= 1) {
					let string = `${Math.floor(diff / 60)}h`;
					if (diff % 60 > 0) {
						string += `${diff % 60}min`;
					}
					return string;
				}
				return `${diff}min`;
			},
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 100,
			fixed: 'right',
			render: (_, record) =>
				getIsExpired(record) ? (
					<Tooltip title={t('The rate for this draft has expired. Not editable.')}>
						<Button type='link' className='px-0' disabled>
							{t('Edit')}
						</Button>
					</Tooltip>
				) : (
					<Button type='link' className='px-0' onClick={handleEditDraftShipment(record)}>
						{t('Edit')}
					</Button>
				),
		},
	];

	useMount(() => {
		getDraftShipments({ current, pageSize }, { params: {} });
	});

	const [timeoutDelay, setTimeoutDelay] = useState<number | undefined>();
	useEffect(() => {
		// 没有数据，不设置倒计时
		if (draftShipments.length === 0) {
			return setTimeoutDelay(undefined);
		}
		// 所有数据都过期，不设置倒计时
		if (draftShipments.every(getIsExpired)) {
			return setTimeoutDelay(undefined);
		}
		// 找到第一个未过期的数据
		const shipment = draftShipments.findLast((s) => !getIsExpired(s));
		if (!shipment) {
			return setTimeoutDelay(undefined);
		}
		// 计算倒计时
		const delay = LaDayjs(shipment.create_date, 'MM/DD/YYYY HH:mm')
			.add(4, 'hours')
			.tz(dayjs.tz.guess())
			.diff(dayjs().millisecond(0).second(0), 'minutes');
		setTimeoutDelay(delay * 60 * 1000);
		// 卸载时清除倒计时
		return () => {
			setTimeoutDelay(undefined);
		};
	}, [draftShipments]);
	useTimeout(() => {
		getDraftShipments({ current, pageSize }, { params: {} });
	}, timeoutDelay);

	return (
		<>
			<Button type='primary' onClick={handleOpenDrawer}>
				{t('See All Draft Orders')}
			</Button>
			<Drawer
				open={isDrawerOpen}
				width='80%'
				title={t('Draft Orders')}
				onClose={handleCloseDrawer}
				footer={
					<div className='flex justify-center'>
						<Pagination
							total={total}
							current={current}
							pageSize={pageSize}
							onChange={handleChangePagination}
						/>
					</div>
				}
			>
				{isLoading ? (
					<CenterSpin size='large' />
				) : draftShipments.length === 0 ? (
					<Empty />
				) : (
					<Table
						columns={columns}
						rowKey='id'
						dataSource={draftShipments}
						pagination={false}
					></Table>
				)}
			</Drawer>
		</>
	);
};
