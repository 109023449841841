import { useTranslation } from 'react-i18next';
import BolCreated40pxActiveIcon from '@/icons/bol-created-40px-active-icon';
import BolCreated40pxIcon from '@/icons/bol-created-40px-icon';
import Delivered40pxActiveIcon from '@/icons/delivered-40px-active-icon';
import Delivered40pxIcon from '@/icons/delivered-40px-icon';
import Ordered40pxActiveIcon from '@/icons/ordered-40px-active-icon';
import PickedUp40pxActiveIcon from '@/icons/picked-up-40px-active-icon';
import PickedUp40pxIcon from '@/icons/picked-up-40px-icon';
import { Shipment } from '@/interfaces/shipment.interface';
import { useShipmentOrderContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import StatusIcon from '@/pages/order-detail/order-detail-container/components/tracking/shipment-update-step/components/status-icon';
import {
	Item,
	Status,
	StepsProps,
} from '@/pages/order-detail/order-detail-container/components/tracking/shipment-update-step/type';
import useShipmentOrderPath from '../../use-shipment-order-path';
import { formateTimeToHMMA, formatMMDDYYYYToYYYYMMDD, formatTimeToYYYYMMDD } from '../../utils';
import { CFSShipment } from '@/interfaces/cfs-shipment.interface';

const ProcessingLine = ({ status }: { status: Status }) => {
	return (
		<div
			className={`${status === 'finished' ? 'bg-primary-light-02' : 'bg-grey-03'} h-[2px] w-full`}
		/>
	);
};

export const ShipmentUpdateTimeline = () => {
	const { t } = useTranslation();

	const { type } = useShipmentOrderPath();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const {
		create_date,
		actual_pickup_date,
		estimated_pickup_date,
		actual_delivery_date,
		estimated_delivery_date,
	} = data;

	const showItems = [
		'create_date',
		type === 'ltl' || type === 'cfs' ? 'bol_created' : undefined, // TODO 确认这个
		'pickup_date',
		'delivery_date',
	].filter((i) => !!i);

	const itemMap: Record<string, Item> = {
		create_date: {
			title: t('Order Placed'),
			value: create_date,
			content: (
				<div className='w-full h-full flex flex-col items-center'>
					<div className='body-3-b text-black'>{t('Order Placed')}</div>
					<div>{formatMMDDYYYYToYYYYMMDD(create_date)}</div>
					<div>{formateTimeToHMMA(create_date)}</div>
				</div>
			),
			Icon: () => <Ordered40pxActiveIcon />,
		},
		bol_created:
			type === 'ltl'
				? {
						title: t('BOL Created'),
						value: (data as Shipment).bol_created_at,
						content: (
							<div className='w-full h-full flex flex-col items-center'>
								<div className='body-3-b text-black'>{t('BOL Created')}</div>
								<div>
									{formatTimeToYYYYMMDD(
										(data as Shipment).bol_created_at?.split(' ')?.slice(0, 3)?.join(' '),
									)}
								</div>
								<div>{(data as Shipment).bol_created_at?.split(' ')?.slice(3)?.join(' ')}</div>
							</div>
						),
						Icon: ({ status }) =>
							status === 'finished' ? <BolCreated40pxActiveIcon /> : <BolCreated40pxIcon />,
					}
				: {
						title: t('BOL Created'),
						value: (data as CFSShipment).available_at,
						content: (
							<div className='w-full h-full flex flex-col'>
								<div className='text-base flex gap-2'>
									<div className='flex-none w-1/2 text-right body-3-b font-bold'>
										{t('CFS Custom')}
									</div>
									<div className='flex-none w-1/2'>
										{(data as CFSShipment).customs_release_at
											? t('CFS Custom Released')
											: t('CFS Custom Hold')}
									</div>
								</div>
								<div className='text-base flex gap-2'>
									<div className='flex-none w-1/2 text-right font-bold'>{t('CFS Freight')}</div>
									<div className='flex-none w-1/2'>
										{(data as CFSShipment).freight_release_at
											? t('CFS Freight Released')
											: t('CFS Freight Hold')}
									</div>
								</div>
								<div className='text-base flex gap-2'>
									<div className='flex-none w-1/2 text-right font-bold'>{t('CFS Available')}</div>
									<div className='flex-none w-1/2'>
										{(data as CFSShipment).available_at
											? t('CFS Available Yes')
											: t('CFS Available No')}
									</div>
								</div>
							</div>
						),
						Icon: ({ status }) =>
							status === 'finished' ? <BolCreated40pxActiveIcon /> : <BolCreated40pxIcon />,
					},
		pickup_date: {
			title: t('Picked Up'),
			value: actual_pickup_date,
			content: (
				<div className='w-full h-full flex flex-col items-center'>
					<div className='body-3-b text-black'>{t('Picked Up')}</div>
					<div>{formatMMDDYYYYToYYYYMMDD(actual_pickup_date ?? estimated_pickup_date)}</div>
				</div>
			),
			Icon: ({ status }) =>
				status === 'finished' ? <PickedUp40pxActiveIcon /> : <PickedUp40pxIcon />,
		},
		delivery_date: {
			title: t('Delivered'),
			value: actual_delivery_date,
			content: (
				<div className='w-full h-full flex flex-col items-center'>
					<div className='body-3-b text-black'>{t('Delivered')}</div>
					<div>{formatMMDDYYYYToYYYYMMDD(actual_delivery_date ?? estimated_delivery_date)}</div>
				</div>
			),
			Icon: ({ status }) =>
				status === 'finished' ? <Delivered40pxActiveIcon /> : <Delivered40pxIcon />,
		},
	};

	const shipmentUpdateItems: StepsProps['items'] = showItems.map((key) => itemMap[key]);

	const currentIndex =
		(type === 'ltl'
			? [create_date, (data as Shipment).bol_created_at, actual_pickup_date, actual_delivery_date]
			: [create_date, actual_pickup_date, actual_delivery_date]
		).findLastIndex((i) => !!i) + 1;

	const getStatus = useMemoizedFn(
		(current: number, index: number, value: string | undefined | boolean): Status => {
			if (index === current) {
				return value ? 'finished' : 'processing';
			}
			if (index < current) {
				return 'finished';
			}
			return 'waiting';
		},
	);

	return (
		<div
			style={{
				gridTemplateColumns: shipmentUpdateItems
					.map((_, index) => (index === 1 && type === 'cfs' ? '240px auto' : `140px auto`))
					.join(' ')
					.slice(0, -5),
			}}
			className={`w-full h-full grid grid-rows-[repeat(3,auto)]`}
		>
			{/* icons */}
			{shipmentUpdateItems.map((item, index) => {
				const status = getStatus(currentIndex, index, item.value);
				return (
					<>
						<div className='w-full h-full flex-center'>
							<item.Icon key={item.title} status={status} />
						</div>
						{index === shipmentUpdateItems.length - 1 ? null : <div />}
					</>
				);
			})}
			{/* status */}
			{shipmentUpdateItems.map((item, index) => {
				const status = getStatus(currentIndex, index, item.value);
				return (
					<>
						<div className='w-full h-full flex-center'>
							<StatusIcon key={item.title} status={status} value={item.value} />
						</div>
						{index === shipmentUpdateItems.length - 1 ? null : (
							<div className='w-full h-full flex-center'>
								<ProcessingLine status={status} />
							</div>
						)}
					</>
				);
			})}
			{shipmentUpdateItems.map((item, index) => {
				return (
					<>
						<div className='w-full h-full flex-center'>{item.content}</div>
						{index === shipmentUpdateItems.length - 1 ? null : <div />}
					</>
				);
			})}
		</div>
	);
};
