import { useCFSContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';

export const useCFSStepThreeAIAutofill = () => {
	const cfsContext = useCFSContext();

	const { runAsync: getCFSAIAutofillForShipment } = apiHooks.useGetCFSAutofillForShipment();

	const handleAIAutofill = useMemoizedFn(async (content: string) => {
		try {
			const res = await getCFSAIAutofillForShipment({ data: { content } });
			const data = res.data;

			// 更新 pickupAddress
			// if (
			// 	cfsContext.pickupLocation.firms_code &&
			// 	data.pickup_location_firmscode &&
			// 	cfsContext.pickupLocation.firms_code !== data.pickup_location_firmscode
			// ) {
			// 	const pickupLocations = await getCFSLocations({
			// 		params: { name: data.pickup_location_firmscode, limit: 1 },
			// 	});
			// 	const pickupLocation = pickupLocations.data.data[0];
			// 	cfsContext.pickupLocation = pickupLocation;
			// }

			// 更新 destinationAddress
			if (cfsContext.destinationAddress) {
				cfsContext.destinationAddress = {
					...cfsContext.destinationAddress,
					address: data.destination_address1 || cfsContext.destinationAddress.address,
					address2: data.destination_address2 || cfsContext.destinationAddress.address2,
					// zipcode: data.destination_zipcode || cfsContext.destinationAddress.zipcode,
					name: data.destination_company_name || cfsContext.destinationAddress.name,
					contacts: cfsContext.destinationAddress.contacts.map((contact, index) => ({
						...contact,
						name: index === 0 ? data.destination_contact_name || contact.name : contact.name,
						phone: index === 0 ? data.destination_contact_phone || contact.phone : contact.phone,
						email: index === 0 ? data.destination_contact_email || contact.email : contact.email,
					})),
					special_request:
						data.destination_special_request || cfsContext.destinationAddress.special_request,
				};
			}

			// 更新 stepThreeFormValues
			cfsContext.stepThreeFormValues = {
				...cfsContext.stepThreeFormValues,
				customer_reference_number:
					data.customer_reference_number ||
					cfsContext.stepThreeFormValues.customer_reference_number,
				mbl_number: data.mbl_number || cfsContext.stepThreeFormValues.mbl_number,
				hbl_number: data.hbl_number || cfsContext.stepThreeFormValues.hbl_number,
				dropoff_number: data.dropoff_number || cfsContext.stepThreeFormValues.dropoff_number,
				destination_open_time: data.destination_open_time
					? dayjs(data.destination_open_time, 'HH:mm')
					: cfsContext.stepThreeFormValues.destination_open_time,
				destination_close_time: data.destination_close_time
					? dayjs(data.destination_close_time, 'HH:mm')
					: cfsContext.stepThreeFormValues.destination_close_time,
			};
		} catch (error) {
			console.error('Error in handleAIAutofill:', error);
			// 这里可以添加错误处理逻辑，比如���示一个错误提示
		}
	});

	return { handleAIAutofill };
};
