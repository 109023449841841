import { useTranslation } from 'react-i18next';
import { Popover, Segmented } from 'antd';
import { CFSAddressType, CFSAddressTypeLabelMap } from '@/interfaces/cfs-address-type.enum';
import { QuestionCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

interface AddressTypeRadioProps {
	value: CFSAddressType;
	classNames?: string;
	onChange: (value: CFSAddressType) => void;
}

const LimitedAccessInfo = () => {
	const { t } = useTranslation();

	return (
		<Popover
			content={
				<div>
					<div>
						{t(
							'A limited access fee is an additional charge added to your freight bill due to the carrier’s consideration of extra service or time, and may be applied in several different instances.',
						)}
					</div>
					<div>
						{t(
							`This fee can vary by carrier and can be a flat rate or per-hundredweight charge. The cost can range from $50 to $500, depending on the type of restriction and the delivery's specialized nature.`,
						)}
					</div>
					<div>
						{t(
							'This type of service may be applied to a shipment that is being delivered to a commercial establishment that is not open to the walk-in public during the normal business hours of 8 AM-5 PM, or areas that are not normally accessible via main roads. Examples of limited access points may include, but are not limited to:',
						)}
					</div>
					<div>
						{t(
							'construction sites, fairs and carnivals, Event stadiums or venues, military bases, mine sites, prisons, schools or universities, storage facilities, piers, airports, farms and ranches, campgrounds, zoos, reservations, government facilities, hotels/motels or resorts, hospitals, doctor’s offices, strip malls, places of worship.',
						)}
					</div>
				</div>
			}
			overlayClassName='max-w-[50vw]'
		>
			<QuestionCircleOutlined />
		</Popover>
	);
};

export const AddressTypeRadio = ({ value, classNames, onChange }: AddressTypeRadioProps) => {
	const { t } = useTranslation();

	return (
		<>
			<Segmented
				className={clsx(classNames)}
				block
				options={Object.entries(CFSAddressTypeLabelMap).map(
					([value, label]: [CFSAddressType, string]) => ({
						label:
							value === CFSAddressType.LIMITED_ACCESS ? (
								<div className='flex justify-center items-center gap-[4px]'>
									<span>{t(label)}</span> <LimitedAccessInfo />
								</div>
							) : (
								t(label)
							),
						value,
					}),
				)}
				value={value}
				onChange={onChange}
			/>
			{/* <div className='flex items-center'>
				{Object.entries(LTLAddressTypeLabelMap).map(([val, label]: [LTLAddressType, string]) => {
					const isSelected = val === value;

					return (
						<div
							key={val}
							style={{
								backgroundColor: isSelected ? '#22aedf' : 'white',
								color: isSelected ? 'white' : 'black',
							}}
							className='grow box-border h-[30px] flex justify-center items-center border-[1px] border-solid border-grey-03 cursor-pointer rounded-[4px] body-4-b'
							onClick={() => onChange(val)}
						>
							{t(label)}
						</div>
					);
				})}
			</div> */}
		</>
	);
};
