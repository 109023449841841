import { useTranslation } from 'react-i18next';
import { Checkbox, Form, FormInstance, Input, Space } from 'antd';
import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { formatePhoneNumber } from '@/utils/formate-phone-number';
import { FormItem } from '../../form-item';
import { formItemWrapper } from '../../form-item-wrapper';

interface ShipmentDetailItemProps {
	type: 'pickup' | 'destination';
	titleIcon: React.ReactNode;
	borderColor: string;
	form: FormInstance;
	extra?: React.ReactNode;
}

export const ShipmentDetailItem = observer(
	({ type, titleIcon, borderColor, form, extra }: ShipmentDetailItemProps) => {
		const { t } = useTranslation();

		const cfsContext = useCFSContext();

		const title = type === 'pickup' ? t(`Pickup`) : t(`Destination`);

		// 根据 warehouse 的字段对应

		if (type === 'pickup') {
			return (
				<div className='w-full h-full'>
					<div
						style={{
							borderLeft: `2px dashed ${borderColor}`,
						}}
						className='flex flex-col pl-[12px] w-[710px]'
					>
						{/* title */}
						{/* left */}
						<div className='flex justify-between items-center bg-white'>
							<div className='translate-x-[-25px] flex items-center'>
								{titleIcon}
								<span className='body-3-sb text-[#545F71]'>{title}</span>
							</div>
						</div>

						<Gap height='24px' />

						{/* form content */}
						<div className='flex flex-col gap-[24px]'>
							{/* line 1 */}
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('FIRMS Code') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[150px] h-[42px]`}
											value={cfsContext.pickupLocation.firms_code}
										/>
									</Form.Item>
								</FormItem>
								<FormItem titleProps={{ content: t('Facility Name') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[540px] h-[42px]`}
											value={cfsContext.pickupLocation.name}
										/>
									</Form.Item>
								</FormItem>
							</div>
							{/* line 2 */}
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('Address') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[702px] h-[42px]`}
											value={[
												cfsContext.pickupLocation.address1,
												cfsContext.pickupLocation.address2,
											]
												.filter(Boolean)
												.join(', ')}
										></Input>
									</Form.Item>
								</FormItem>
							</div>
							{/* line 3 */}
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('City') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[240px] h-[42px]`}
											value={cfsContext.pickupLocation.city.name}
										></Input>
									</Form.Item>
								</FormItem>
								<FormItem titleProps={{ content: t('State') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[220px] h-[42px]`}
											value={cfsContext.pickupLocation.city.state}
										></Input>
									</Form.Item>
								</FormItem>
								<FormItem titleProps={{ content: t('Zipcode') }}>
									<Form.Item>
										<Input
											disabled
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[220px] h-[42px]`}
											value={cfsContext.pickupLocation.zipcode}
										></Input>
									</Form.Item>
								</FormItem>
							</div>
							{/* line 4 */}
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('Special Requirement') }}>
									<Form.Item>
										<Input
											maxLength={255}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[445px] h-[42px]`}
											value={cfsContext.pickupSpecialRequest}
											onChange={(e) => (cfsContext.pickupSpecialRequest = e.target.value)}
										/>
									</Form.Item>
								</FormItem>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='w-full h-full'>
				<div
					style={{
						borderLeft: `2px dashed ${borderColor}`,
					}}
					className='flex flex-col pl-[12px] w-[710px]'
				>
					{/* title */}
					{/* left */}
					<div className='flex justify-between items-center bg-white'>
						<div className='translate-x-[-25px] flex items-center'>
							{titleIcon}
							<span className='body-3-sb text-[#545F71]'>{title}</span>
						</div>

						{/* right */}
						<div className='flex items-center'>
							<Checkbox
								className='body-4-m text-black'
								value={cfsContext.saveDestinationAddress}
								onChange={(value) => {
									cfsContext.saveDestinationAddress = value.target.checked;
								}}
							>
								{t(`Save to Address Book`)}
							</Checkbox>
						</div>
					</div>

					<Gap height='24px' />

					{/* form content */}
					<div className='flex flex-col gap-[24px]'>
						{/* line 1 */}
						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('x Facility', { x: title }), required: true }}>
								<Form.Item name='name' rules={[{ required: true }]}>
									<Input
										maxLength={255}
										placeholder={t(`e.g. Abc Logistics Inc.`)}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[150px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('x Contact', { x: title }), required: true }}>
								<Form.Item name={['contacts', '0', 'name']} rules={[{ required: true }]}>
									<Input
										maxLength={255}
										placeholder='Peter Wong'
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[140px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<Space.Compact>
								<FormItem titleProps={{ content: t('x Phone', { x: title }), required: true }}>
									<Form.Item name={['contacts', '0', 'phone']} rules={[{ required: true }]}>
										<Input
											maxLength={255}
											placeholder='(672)271-4587'
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[150px] h-[42px]`}
											onChange={(e) => {
												const data = e.target.value ?? '';
												const formattedPhone = formatePhoneNumber(data);
												cfsContext.destinationAddress.contacts[0].phone = formattedPhone;
												form.setFieldValue(['contacts', '0', 'phone'], formattedPhone);
											}}
										/>
									</Form.Item>
								</FormItem>

								<FormItem titleProps={{ content: t('Ext') }}>
									<Form.Item name={['contacts', '0', 'phone_ext']}>
										<Input
											maxLength={5}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[70px] h-[42px]`}
										/>
									</Form.Item>
								</FormItem>
							</Space.Compact>

							<FormItem titleProps={{ content: t('x E-mail', { x: title }), required: true }}>
								<Form.Item
									name={['contacts', '0', 'email']}
									rules={[
										{ required: true },
										{
											type: 'email',
											message: t('email is not a valid email'),
										},
									]}
								>
									<Input
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[150px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						{/* line 2 */}
						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('Address1'), required: true }}>
								<Form.Item name='address' rules={[{ required: true }]}>
									<Input
										maxLength={255}
										placeholder='17550 NE Brown Ave'
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[270px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('Address2') }}>
								<Form.Item name='address2'>
									<Input
										placeholder='Ste200'
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[100px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('City, State & Country'), required: true }}>
								<Form.Item name={['city', 'full_name']}>
									<Input
										disabled
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[194px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('Zip Code'), required: true }}>
								<Form.Item name='zipcode' rules={[{ required: true }]}>
									<Input
										disabled
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[94px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						{/* line 3 */}
						<div className='flex gap-[12px]'>{extra}</div>

						{/* line 4 */}
						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('Special Requirement') }}>
								<Form.Item name={'special_request'}>
									<Input
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[445px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>
					</div>
				</div>
			</div>
		);
	},
);
