import { useCFSContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { message } from '@/utils/message';
import { useGenerateAddress } from './use-generate-address';
import useCFSSearchRateParams from './use-cfs-search-rate-params';
import { getCFSLocation, pullCFSQuoteRates } from '@/services/apis';
import { getCFSDraftShipment } from '@/services/apis';
import { CFSLocation } from '@/interfaces/cfs-location.interface';

export const useInitQuote = () => {
	const cfsContext = useCFSContext();

	const { parsedSearchParams } = useCFSSearchRateParams();

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(true);

	const { runAsync: getQuote } = apiHooks.useGetCFSQuote({ manual: true });

	const { generateAddress } = useGenerateAddress();

	const initQuote = useMemoizedFn(async () => {
		try {
			startLoading();

			const res = await getQuote({
				path: {
					id: parsedSearchParams.quote_id,
				},
			});

			const quote = res.data.data;

			const pickupLocation =
				(
					await getCFSLocation({
						path: {
							id: quote.pickup_cfs_location_id,
						},
					})
				)?.data?.data ?? ({} as CFSLocation);

			const destinationAddress = await generateAddress({
				city_name: quote.destination_city_name,
				state_name: quote.destination_state_name,
				zipcode: quote.destination_zipcode,
			});

			if (pickupLocation) {
				cfsContext.pickupLocation = pickupLocation;
			}
			if (destinationAddress) {
				cfsContext.destinationAddress = destinationAddress;
			}

			cfsContext.stepOneFormValues = {
				pickup_cfs_location_id: quote.pickup_cfs_location_id,
				pickup_date:
					parsedSearchParams.type === 'copy'
						? undefined
						: quote.pickup_date
							? dayjs(quote.pickup_date)
							: undefined, // 提货日期
				items:
					quote.items.length > 0
						? quote.items.map((item) => ({ ...item, id: Math.random().toString() }))
						: [],
				pickup_accessorials: quote.pickup_accessorials, // 提货附加服务
				destination_accessorials: quote.destination_accessorials, // 目的地附加服务
				shipment_accessorials: quote.shipment_accessorials, // 运输附加服务
			};
			cfsContext.destinationAddressType = quote.destination_address_type;
			cfsContext.calcItemFreightClass();

			if (parsedSearchParams.draft_shipment_id) {
				const response = await pullCFSQuoteRates({ path: { quote_id: quote.id } });
				// 报价可能失效，需要更新
				cfsContext.selectedQuoteRate = response.data.data.rates.find(
					(r) => r.id == parsedSearchParams.rate_id,
				);
				const draftShipment = (
					await getCFSDraftShipment({
						path: { draftShipmentId: parsedSearchParams.draft_shipment_id },
					})
				).data.data;
				// 回显页面 pickup special request 相关内容
				cfsContext.pickupSpecialRequest = draftShipment.pickup_special_request;
				// 回显页面 destination address 相关内容
				cfsContext.destinationAddress = {
					...cfsContext.destinationAddress,
					name: draftShipment.destination_company_name || cfsContext.destinationAddress.name || '',
					contacts: [
						// 我们不需要匹配完整的 contact 类型，只需要页面需要显示的部分
						// @ts-expect-error types not match
						{
							name: draftShipment.destination_contact_name || '',
							email: draftShipment.destination_contact_email || '',
							phone: draftShipment.destination_contact_phone || '',
							phone_ext: draftShipment.destination_contact_phone_ext || '',
						},
						...(cfsContext.destinationAddress.contacts ?? []),
					],
					address:
						draftShipment.destination_address1 || cfsContext.destinationAddress.address || '',
					address2:
						draftShipment.destination_address2 || cfsContext.destinationAddress.address2 || '',
					special_request:
						draftShipment.destination_special_request ||
						cfsContext.destinationAddress.special_request ||
						'',
				};
				cfsContext.stepThreeFormValues = {
					customer_reference_number: draftShipment.customer_reference_number || undefined,
					dropoff_number: draftShipment.dropoff_number || undefined,
					dropoff_instruction: draftShipment.dropoff_instruction || undefined,
					mbl_number: draftShipment.mbl_number || undefined,
					hbl_number: draftShipment.hbl_number || undefined,

					destination_open_time: draftShipment.destination_open_time
						? dayjs(draftShipment.destination_open_time, 'HH:mm')
						: undefined,
					destination_close_time: draftShipment.destination_close_time
						? dayjs(draftShipment.destination_close_time, 'HH:mm')
						: undefined,
					estimated_delivery_date: draftShipment.estimated_delivery_date
						? dayjs(draftShipment.estimated_delivery_date)
						: undefined,

					is_insurance_entrusted: draftShipment.is_insurance_entrusted ?? true,
					// @ts-expect-error types not match
					cargo_value: draftShipment.cargo_value ?? undefined,

					isCheckAcknowledge: false,
				};
			}

			if (parsedSearchParams.type === 'copy') {
				cfsContext.step = 1;
			} else if (parsedSearchParams.draft_shipment_id) {
				cfsContext.quote = quote;
				// 如果报价未失效且已经选中了报价，则跳转到 step 3，否则跳转到 step 2
				cfsContext.step = cfsContext.selectedQuoteRate ? 3 : 2;
			} else {
				cfsContext.quote = quote;
				cfsContext.step = 2;
			}
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
		}
	});

	useMount(() => {
		if (!cfsContext.quote && parsedSearchParams.quote_id) {
			initQuote();
		} else {
			endLoading();
		}
	});

	return {
		loading,
	};
};
