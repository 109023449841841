import { useTranslation } from 'react-i18next';
import { useShipmentOrderContext } from '@/services/contexts';
import { Item } from '../item';
import { Shipment } from '@/interfaces/shipment.interface';

export const ReferenceAndInstructions = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder as Shipment;

	const { customer_reference_number, pickup_number, dropoff_number } = data;

	return (
		<div className='flex flex-col gap-[24px]'>
			<div className='h4-b text-[#333333]'>{t('Reference numbers & instructions')}</div>

			<div className='grid grid-cols-2 grid-rows-[repeat(3,auto)] gap-x-[160px] gap-y-[16px]'>
				<Item title={t(`Customer reference number`)} content={customer_reference_number} />
				<div></div>
				<Item title={t(`Pickup number`)} content={pickup_number} />
				<Item title={t(`Dropoff number`)} content={dropoff_number} />
			</div>
		</div>
	);
};
