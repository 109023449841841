import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Form, FormInstance, message, Modal, Tooltip } from 'antd';
import Calendar18pxIcon from '@/icons/calendar-18px-icon';
import { CreateQuotePropsData } from '@/services/apis';
import { useLTLContext } from '@/services/contexts';
import { useMemoizedFn, useSetState } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { AIAutofillBtn } from '../../components/ai-autofill-btn';
import { Gap } from '@/components/gap';
import { QuoteId } from '@/components/quote-id';
import { apiHooks } from '@/hooks/apis';
import { useIsPickupDateDisabled } from '@/hooks/use-is-pickup-date-disabled';
import { formItemWrapper } from '../../form-item-wrapper';
import useLtlSearchRateParams from '../../use-ltl-search-rate-params';
import './index.less';
import { ItemInfo } from './item-info';
import { PalletCalculator } from './ltl-pallet-calculator';
import { PickupDateStatement } from './pickup-date-statement';
import { useLTLStepOneAIAutofill } from './use-ltl-step-one-ai-autofill';
import { ZipCodePicker } from './zip-code-picker';
import { InfoCircleOutlined } from '@ant-design/icons';

// 确保路径正确

const StepOneForm = observer(({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const { parsedSearchParams, changeSearchParams } = useLtlSearchRateParams();

	const { runAsync: createQuote } = apiHooks.useCreateQuote();

	const { runAsync: updateQuote } = apiHooks.useUpdateQuote();

	const { isPickupDateDisabled } = useIsPickupDateDisabled({ whiteListType: 'ltl' });

	const { handleAIAutofill, handleAIAutofillFromImage } = useLTLStepOneAIAutofill();

	const [customRequiredError, setCustomRequiredError] = useSetState({
		pickup: {
			isError: false,
			reason: '',
		},
		destination: {
			isError: false,
			reason: '',
		},
		pickup_date: {
			isError: false,
			reason: '',
		},
	});

	const [pickerValue, setPickerValue] = useState(dayjs().tz('America/Los_Angeles'));

	const handleQuote = useMemoizedFn((data: CreateQuotePropsData, quoteId?: number) => {
		return quoteId ? updateQuote({ path: { id: quoteId }, data }) : createQuote({ data });
	});

	const makeCustomRequiredError = useMemoizedFn(
		(field: 'pickup' | 'destination' | 'pickup_date', reason: string) => {
			// @ts-expect-error history issue
			setCustomRequiredError({
				[field]: {
					isError: true,
					reason,
				},
			});
		},
	);

	const removeCustomRequiredError = useMemoizedFn(
		(field: 'pickup' | 'destination' | 'pickup_date') => {
			// @ts-expect-error history issue
			setCustomRequiredError({
				[field]: {
					isError: false,
					reason: '',
				},
			});
		},
	);

	const handleNext = useMemoizedFn(async () => {
		// get total count of unit in items
		const totalUnits = ltlContext.stepOneFormValues.items.reduce(
			(acc, item) => acc + item.units,
			0,
		);

		if (totalUnits > 26) {
			return message.warning(t('The total number of units cannot exceed 26.'));
		}

		try {
			ltlContext.stepNextButtonProps.loading = true;

			let hasError = false;
			if (!ltlContext?.pickupAddress?.zipcode) {
				makeCustomRequiredError('pickup', t('Required'));
				hasError = true;
			}

			if (!ltlContext?.destinationAddress?.zipcode) {
				makeCustomRequiredError('destination', t('Required'));
				hasError = true;
			}

			if (!ltlContext?.stepOneFormValues?.pickup_date) {
				makeCustomRequiredError('pickup_date', t('Required'));
				hasError = true;
			}

			await form.validateFields();

			if (hasError) {
				return;
			}

			let resolve: () => void;
			let reject: (reason?: any) => void;
			const p = new Promise<void>((res, rej) => {
				resolve = res;
				reject = rej;
			});
			if (ltlContext.isSizeOrWeightLimitationsExceeded()) {
				return Modal.warning({
					content: t(
						'The dimensions or weight of the goods exceed the limit under the selected address type or additional services. Please make adjustments and try the quote again.',
					),
					footer: (_, { OkBtn }) => <OkBtn />,
				});
			}
			if (ltlContext.calcIsLiftGateRequired()) {
				Modal.confirm({
					content: (
						<div>
							<div>
								{t(
									`The size or weight of your shipment exceeds the maximum limit of the Liftgate service. The service provider may not be able to provide the service or charge additional fees.`,
								)}
							</div>
							<div>
								{t(
									`If you have any questions, please contact your dedicated account manager. Click on the Continue button to get a quote.`,
								)}
							</div>
						</div>
					),
					okText: t('Continue'),
					cancelText: t('Cancel'),
					onOk() {
						resolve();
					},
					onCancel() {
						reject();
					},
				});
			} else {
				resolve();
			}

			try {
				await p;
			} catch (e) {
				return;
			}

			// 如果有 quote id，更新而非创建
			const res = await handleQuote(
				{
					pickup_address_type: ltlContext.pickUpAddressType,
					pickup_accessorials: ltlContext.stepOneFormValues.pickup_accessorials ?? [],
					destination_address_type: ltlContext.destinationAddressType,
					destination_accessorials: ltlContext.stepOneFormValues.destination_accessorials ?? [],
					shipment_accessorials: ltlContext.stepOneFormValues.shipment_accessorials ?? [],

					pickup_zipcode: ltlContext.pickupAddress.zipcode,
					destination_zipcode: ltlContext.destinationAddress.zipcode,
					pickup_city_id: ltlContext.pickupAddress.city.id,
					destination_city_id: ltlContext.destinationAddress.city.id,
					pickup_date: dayjs(ltlContext.stepOneFormValues.pickup_date).format('YYYY-MM-DD'),
					items: ltlContext.stepOneFormValues.items.map((item) => {
						const newItem = { ...item };
						delete newItem['id'];
						return {
							...newItem,
							description: newItem.description.trim(),
						};
					}),
				},
				ltlContext.quote?.id,
			);
			// 修改 params，需要保留已有 params，否则页面可能显示错误内容
			changeSearchParams({ ...parsedSearchParams, quote_id: res.data.data.uid });
			ltlContext.quote = res.data.data;
			ltlContext.step = 2;
		} catch (error) {
			console.error(`ltl step one handleNext error`, error);
		} finally {
			ltlContext.stepNextButtonProps.loading = false;
		}
	});

	useEffect(() => {
		ltlContext.stepNextHandler = () => void handleNext();
	}, [form, handleNext]);

	// sync value
	useEffect(() => {
		form.setFieldsValue(ltlContext.stepOneFormValues);
	}, [
		ltlContext.stepOneFormValues,
		ltlContext.stepOneFormValues.pickup_date,
		ltlContext.stepOneFormValues.pickup_accessorials,
		ltlContext.stepOneFormValues.destination_accessorials,
		ltlContext.stepOneFormValues.shipment_accessorials,
		ltlContext.stepOneFormValues.items,
	]);

	useEffect(() => {
		ltlContext.stepNextButtonProps.disabled = false;
	}, []);

	// 错误去除监听
	useEffect(() => {
		if (ltlContext?.pickupAddress?.zipcode) {
			removeCustomRequiredError('pickup');
		}
		if (ltlContext?.destinationAddress?.zipcode) {
			removeCustomRequiredError('destination');
		}
		if (ltlContext.stepOneFormValues.pickup_date) {
			removeCustomRequiredError('pickup_date');
		}
	}, [
		ltlContext?.pickupAddress?.zipcode,
		ltlContext?.destinationAddress?.zipcode,
		ltlContext.stepOneFormValues.pickup_date,
	]);

	return (
		<>
			{/* Pickup & Delivery  */}
			<div className='grow-0 shrink-0 overflow-hidden'>
				<div className='flex items-baseline gap-[12px]'>
					<div className='h4-b text-theme-1-1-10'>{t(`Pickup & Delivery`)}</div>
					<AIAutofillBtn
						handleAIAutofill={handleAIAutofill}
						handleAIAutofillFromImage={handleAIAutofillFromImage}
					/>
					<QuoteId quoteId={ltlContext.quote?.uid} />
				</div>

				<Gap height='24px' />

				<div>
					<ZipCodePicker errors={customRequiredError} />

					<Gap height='16px' />

					<div className='ml-[61px] pb-[1em]'>
						<div className='flex gap-[4px]'>
							<div
								style={{
									...formItemWrapper.style,
									...(customRequiredError.pickup_date.isError ? { borderColor: '#ff4d4f' } : {}),
								}}
								className={`${formItemWrapper.className}  p-[10px_12px] w-[510px] h-[42px]`}
							>
								<Calendar18pxIcon />
								<Form.Item name='pickup_date' className='grow' help=''>
									<DatePicker
										suffixIcon={null}
										placeholder={t(`Pickup Date`)}
										variant='borderless'
										className='w-full'
										pickerValue={pickerValue}
										cellRender={(current: Dayjs, { originNode, type }) => {
											if (type === 'date') {
												return <div>{current.date()}</div>;
											}
											return originNode;
										}}
										disabledDate={(current) => isPickupDateDisabled(current)}
										onPickerValueChange={(v) => setPickerValue(v)}
									/>
								</Form.Item>
							</div>
							<PickupDateStatement />
						</div>
						<div className='h-[22px] text-[#ff4d4f]'>
							{customRequiredError.pickup_date.isError
								? customRequiredError.pickup_date.reason
								: ''}
						</div>
					</div>
				</div>
			</div>

			{/* <Gap height='8px' /> */}

			{/* Item Information */}
			<div>
				{/* <div className='h4-b text-theme-1-1-10'>{t(`Item Information`)}</div> */}

				{/* 引入 PalletCalculator 组件,按钮放在上面标题的右边 */}
				<div className='flex items-center gap-[12px]'>
					<div className='h4-b text-theme-1-1-10'>{t(`Item Information`)}</div>
					<Tooltip
						title={t(
							`Kindly please ensure that you provide accurate pallet dimensions and weight when placing your order. If not, it may result in substantial additional charges from the carrier.`,
						)}
					>
						<InfoCircleOutlined />
					</Tooltip>

					<QuoteId quoteId={ltlContext.quote?.uid} />

					<PalletCalculator />
				</div>

				<Gap height='16px' />

				<div className='flex flex-col gap-[24px]'>
					{ltlContext.stepOneFormValues.items.map((item, index) => (
						<ItemInfo key={item.id} item={item} index={index} />
					))}
				</div>

				<Gap height='16px' />

				<div
					className=' box-border p-[4px_10px] w-fit border-[1px] border-solid border-grey-01 rounded-[4px] body-4-sb text-grey-01 flex justify-center items-center cursor-pointer'
					onClick={() => ltlContext.addStepOneFormValuesItem()}
				>
					{t(`Add Items`)}
				</div>
			</div>

			<Gap height='24px' />

			{/* Shipment Accessorial */}
			{/* <div>
				<div className='h4-b text-theme-1-1-10'>{t(`Shipment Accessorial`)}</div>
				<Gap height='12px' />

				<Form.Item name='shipment_accessorials'>
					<Checkbox.Group
						options={shipmentAccesorials.map((i) => ({ label: t(i.name), value: i.key }))}
						className='body-4-m text-black'
					/>
				</Form.Item>
			</div> */}
		</>
	);
});

export const StepOne = observer(() => {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const ltlContext = useLTLContext();

	return (
		<Form
			form={form}
			validateMessages={{
				required: t(`Required`),
			}}
			className=' box-border p-[48px_0] w-full h-full flex flex-col overflow-x-hidden overflow-y-scroll step-form'
			onValuesChange={(changedValues, allValues) => {
				ltlContext.stepOneFormValues = allValues;
				if (changedValues.items) {
					ltlContext.calcItemFreightClass();
				}
			}}
		>
			<StepOneForm form={form} />
		</Form>
	);
});
