import { useTranslation } from 'react-i18next';
import TrendUpIcon from '@/icons/trend-up-icon';
import { Column, ColumnConfig } from '@ant-design/charts';
import { ArrElement } from '@/utils/array-element.type';
import { Gap } from '../gap';

interface ChartProps {
	data: {
		month: string; // "Nov"
		total_containers: number;
		increase: number;
	}[];
	extra?: React.ReactNode;
}

// data1: pre
const diff = (
	data1: ArrElement<ChartProps['data']> | undefined,
	data2: ArrElement<ChartProps['data']> | undefined,
) => {
	if (data1 == null || data2 == null || data1.total_containers == 0) {
		return {
			valid: false,
			diffContainers: undefined,
			diffIncreasePercent: undefined,
		};
	}

	const diffContainers = data2.total_containers - data1.total_containers;
	const diffIncreasePercent = ((diffContainers / data1.total_containers) * 100).toFixed(2);

	return {
		valid: true,
		diffContainers,
		diffIncreasePercent,
	};
};

export const ProjectContainersColumnChart = ({ data, extra }: ChartProps) => {
	const { t } = useTranslation();

	const currentData = data.at(-1);
	const lastMonthData = data.at(-2);

	const diffData = diff(lastMonthData, currentData);

	const isIncreased = diffData.diffContainers >= 0;

	const config: ColumnConfig = {
		data: data.map((i, index) => {
			const preData = data[index - 1];
			return {
				...i,
				_diff: diff(preData, i),
			};
		}),
		xField: 'year',
		xAxis: {
			label: {
				formatter: (text) => {
					return text.split(',')[0];
				},
			},
		},
		yField: 'total_containers',
		autoFit: true,
		color: '#C8E3FF',
		minColumnWidth: 18,
		maxColumnWidth: 18,
		interactions: [{ type: 'element-active' }],
		state: {
			active: {
				style: {
					fill: 'l(0.3) 0:#0094FF 1:#0165FA',
					stroke: 'l(0.3) 0:#0094FF 1:#0165FA',
				},
			},
		},
		tooltip: {
			domStyles: {
				'g2-tooltip': {
					padding: '12px',
					backgroundColor: '#F3F9FF',
				},
			},
			customContent(title, _data) {
				const currentData = _data?.[0]?.data;
				const valid = currentData?._diff?.valid;
				const diffContainers = currentData?._diff?.diffContainers;
				const diffIncreasePercent = currentData?._diff?.diffIncreasePercent;
				const isIncreased = diffContainers >= 0;

				return (
					<div className='p-[2px] bg-[#F3F9FF]'>
						<div className='mb-[8px] text-black font-[700] text-[14px]'>{title}</div>

						<div className='mb-[8px] flex justify-between'>
							<div className='mr-[25px] flex items-center'>
								<div className='mr-[7px] w-[9px] h-[9px] bg-[#0075FF]'></div>
								<div className='text-[#747578] font-[700] text-[12px]'>{t('Total containers')}</div>
							</div>

							<div className='text-[#003166] font-[700] text-[12px]'>
								{currentData?.total_containers ?? 0}
							</div>
						</div>

						{valid && (
							<div className='flex items-center'>
								<div
									style={{ transform: isIncreased ? '' : 'rotateX(180deg)' }}
									className='mr-[4px]'
								>
									<TrendUpIcon />
								</div>
								<span
									className={`mr-[4px] foot-note-1-b isIncreased ${
										isIncreased ? 'text-[#73D13D]' : ' text-updated-red'
									}`}
								>{`${diffContainers} (${diffIncreasePercent}%)`}</span>
								<span className='foot-note-1-m text-grey-01'>{t(`Past Month`)}</span>
							</div>
						)}
					</div>
				);
			},
		},
	};

	return (
		<div className='w-full h-full flex flex-col'>
			{/* header */}
			<div className='grow-0 shrink-0 h-[34px] flex justify-between items-center'>
				{/* left */}
				<div className='flex items-center'>
					<div
						style={{
							background: 'linear-gradient(90deg, #0094FF -8.68%, #0165FA 100%)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
							display: 'inline',
						}}
						className='mr-[12px] h3-b'
					>
						{currentData?.total_containers ?? 0}
					</div>

					{diffData.valid && (
						<div className='flex items-center'>
							<div style={{ transform: isIncreased ? '' : 'rotateX(180deg)' }} className='mr-[4px]'>
								<TrendUpIcon />
							</div>
							<span
								className={`mr-[4px] foot-note-1-b isIncreased ${
									isIncreased ? 'text-[#73D13D]' : ' text-updated-red'
								}`}
							>{`${diffData.diffContainers} (${diffData.diffIncreasePercent}%)`}</span>
							<span className='foot-note-1-m text-grey-01'>{t(`Past Month`)}</span>
						</div>
					)}
				</div>

				{/* right */}
				{extra}
			</div>

			<Gap height='16px' />

			{/* chart */}
			<Column className='chart-container grow shrink overflow-hidden' {...config} />
		</div>
	);
};
