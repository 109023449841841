import { useTranslation } from 'react-i18next';
import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { message } from 'antd';

export const ShipmentInfoEditButton = observer(
	({ quoteRateCompleted }: { quoteRateCompleted: boolean }) => {
		const { t } = useTranslation();

		const cfsContext = useCFSContext();

		const handleClick = () => {
			if (!quoteRateCompleted) {
				return void message.open({
					key: 'quote-rate-processing',
					type: 'warning',
					content: t('The prices are being processed. Please wait a moment.'),
				});
			}
			cfsContext.step = 1;
			return;
		};

		return (
			<div
				className=' box-border p-[4px_10px] w-fit border-[1px] border-solid border-grey-01 rounded-[4px] body-4-sb text-grey-01 flex justify-center items-center cursor-pointer'
				onClick={handleClick}
			>
				{t(`Edit shipment`)}
			</div>
		);
	},
);
