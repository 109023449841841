import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSDraftShipmentProps, GetCFSDraftShipmentRes } from './interface';

export const getCFSDraftShipment = async (props: GetCFSDraftShipmentProps) => {
	return await axios.get<GetCFSDraftShipmentRes>(
		`/cfs/draftShipments/${props.path.draftShipmentId}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
