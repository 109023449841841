import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSDraftShipmentsProps, GetCFSDraftShipmentsRes } from './interface';

export const getCFSDraftShipments = async (props: GetCFSDraftShipmentsProps) => {
	return await axios.get<GetCFSDraftShipmentsRes>(`/cfs/draftShipments`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
