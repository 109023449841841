import { useTranslation } from 'react-i18next';
import { Button, Input, Select, SelectProps } from 'antd';
import Search16pxIcon from '@/icons/search-16px-icon';
import { ReactNode } from 'react';
import { CalendarOutlined, SyncOutlined } from '@ant-design/icons';

export interface LTLFTLFilterProps {
	value: {
		query?: string;
		last_days?: string;
		business_status?: string[];
		customer_status?: string[];
	};
	statusFieldName: 'business_status' | 'customer_status';
	type: 'quote' | 'order';
	statusOptions: SelectProps['options'];
	handleChange: (value: LTLFTLFilterProps['value']) => any;
	enableLastDays?: boolean;
	extra?: ReactNode;
	searchPlaceholder?: string;
}

export const LTLFTLFilter = ({
	value,
	statusFieldName,
	type,
	statusOptions,
	enableLastDays,
	handleChange,
	extra,
	searchPlaceholder,
}: LTLFTLFilterProps) => {
	const { t } = useTranslation();

	const _searchPlaceholder =
		searchPlaceholder ||
		(type === 'quote' ? t(`Search Zipcode, Quote#`) : t(`Search Zipcode, Order #, Reference #`));

	return (
		<div className='w-full h-full flex items-center gap-[12px]'>
			<Input
				value={value.query}
				prefix={<Search16pxIcon />}
				placeholder={_searchPlaceholder}
				className='box-border h-[42px] w-[400px] border-[1px] border-solid border-grey-04'
				onChange={(e) => handleChange({ query: e.target.value })}
			/>

			{enableLastDays && (
				<div className=' box-border p-[0_12px] h-[42px] w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<CalendarOutlined />
					<Select
						value={value.last_days}
						suffixIcon={null}
						showSearch
						filterOption={false}
						variant='borderless'
						options={[
							{ label: t(`Last 30 days`), value: '30' },
							{ label: t(`Last 60 days`), value: '60' },
							{ label: t(`Last 90 days`), value: '90' },
							{ label: t('all'), value: 'all' },
						]}
						className='w-full'
						onChange={(value) => handleChange({ last_days: value })}
					/>
				</div>
			)}

			<div className=' box-border p-[0_12px] h-[42px] min-w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
				<div></div>
				<Select
					value={value[statusFieldName]}
					suffixIcon={null}
					mode='multiple'
					showSearch
					filterOption={false}
					variant='borderless'
					options={statusOptions}
					placeholder={t(`Status`)}
					allowClear
					className='w-full'
					onChange={(value) => handleChange({ [statusFieldName]: value })}
				/>
			</div>

			<Button
				type='link'
				icon={<SyncOutlined />}
				onClick={() =>
					handleChange(
						enableLastDays
							? {
									query: '',
									last_days: '30',
									[statusFieldName]: undefined,
								}
							: {
									query: '',
									[statusFieldName]: undefined,
								},
					)
				}
			>
				{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
			</Button>

			{extra}
		</div>
	);
};
