import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Form, FormInstance, message, Tooltip, Modal } from 'antd';
import { QuoteBusinessStatus } from '@/interfaces/cfs-quote.interface';
import { PullCFSQuoteRatesRes } from '@/services/apis';
import { useCFSContext } from '@/services/contexts';
import {
	InfoCircleOutlined,
	LoadingOutlined,
	CaretUpOutlined,
	CaretDownOutlined,
} from '@ant-design/icons';
import { useBoolean, useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { QuoteId } from '@/components/quote-id';
import { Table, TSortOrder } from '@/components/table';
import { apiHooks } from '@/hooks/apis';
import { formItemWrapper } from '../../form-item-wrapper';
import { ShipmentInfoEditButton, ShipmentInfoMap, ShipmentInfoPickupDate } from '../shipment-info';
import { ShipmentInfoCargoSummary } from '../shipment-info/shipment-info-cargo-summary';
import { ShipmentInfoDestinationAddress } from '../shipment-info/shipment-info-destination-address';
import { ShipmentInfoPickupLocation } from '../shipment-info/shipment-info-pickup-location';
import { Rate } from '@/components/rate';
import CarrierCol from './carrier-col';

const StepTwoForm = observer(({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	const cfsContext = useCFSContext();

	const [modal, contextHolder] = Modal.useModal();

	const quoteRates = cfsContext.quoteRates;

	const [
		quoteRateCompleted,
		{ setTrue: setQuoteRateCompleted, setFalse: unsetQuoteRateCompleted },
	] = useBoolean(false);

	const { runAsync: pullCFSQuoteRates, cancel: stopPullCFSQuoteRates } =
		apiHooks.usePullCFSQuoteRates({
			pollingInterval: 3000,
			onSuccess(data: { data: PullCFSQuoteRatesRes }) {
				cfsContext.quoteRates = data.data.data.rates;
				const [current, total] = data.data.data.quoteStatus.split('/').map(Number);
				if (current >= total) {
					stopPullCFSQuoteRates();
					setQuoteRateCompleted();
				}
			},
		});

	const { runAsync: getQuote } = apiHooks.useGetCFSQuote();

	const handleNext = useMemoizedFn(async () => {
		if (!quoteRateCompleted) {
			return message.open({
				key: 'quote-rate-processing',
				type: 'warning',
				content: t('The prices are being processed. Please wait a moment.'),
			});
		}

		if (cfsContext.selectedQuoteRate?.shipping_type === 2) {
			return modal.info({
				content: t('Please contact our customer service for more information.'),
			});
		}

		try {
			cfsContext.stepNextButtonProps.loading = true;

			await form.validateFields();

			if (!cfsContext.selectedQuoteRate || !cfsContext.quote.id) {
				return;
			}

			const quoteRes = await getQuote({ path: { id: cfsContext.quote.id } });

			const quote = quoteRes.data.data;

			if (
				[QuoteBusinessStatus.CONFIRMED, QuoteBusinessStatus.SUBMITED].includes(
					quote.business_status,
				)
			) {
				cfsContext.step = 3;
			} else {
				message.error(
					t(`This Quote is expired or booked, please use Search Rate page to get the new Rate.`),
				);
			}
		} finally {
			cfsContext.stepNextButtonProps.loading = false;
		}
	});

	useEffect(() => {
		cfsContext.stepNextHandler = () => handleNext();
	}, [handleNext]);

	useEffect(() => {
		cfsContext.stepNextButtonProps.disabled = !cfsContext.selectedQuoteRate;
	}, [cfsContext.selectedQuoteRate]);

	// 暂时关闭
	// useEffect(() => {
	// 	cfsContext.stepNextButtonProps.disabled = true;
	// }, []);

	useEffect(() => {
		unsetQuoteRateCompleted();
		cfsContext.selectedQuoteRate = undefined;
		cfsContext.quoteRates = [];

		pullCFSQuoteRates({ path: { quote_id: cfsContext.quote?.id } });

		setTimeout(() => {
			if (!quoteRateCompleted) {
				stopPullCFSQuoteRates();
				setQuoteRateCompleted();
			}
		}, 1000 * 60);
	}, []);

	return (
		<>
			<QuoteId quoteId={cfsContext.quote?.uid} />
			<div className='grow-0 h-[272px] w-full flex'>
				{/* map */}
				<div
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} border-[2px] grow shrink h-full`}
				>
					<ShipmentInfoMap />
				</div>

				<Gap width='24px' />

				{/* Insurance */}
				<div
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} border-[2px] p-[4px] h-full flex flex-col`}
				>
					{/* map info */}
					<div className='grow box-border p-[4px] w-[600px] flex flex-col'>
						<div className='w-full justify-between flex items-center gap-[24px]'>
							<ShipmentInfoPickupDate />
							{cfsContext.quote.business_status === QuoteBusinessStatus.SUBMITED && (
								<ShipmentInfoEditButton quoteRateCompleted={quoteRateCompleted} />
							)}
						</div>

						<Gap height='4px' />

						<div className='w-full grow shrink flex'>
							<div className=' box-border w-[0] grow'>
								<ShipmentInfoPickupLocation />
							</div>
							{/* <div>
								<div
									className='w-0 h-full m-[0_16px]'
									style={{ border: '1px solid #c8c8c8' }}
								></div>
							</div> */}
							<div className='box-border w-[0] grow'>
								<ShipmentInfoDestinationAddress />
							</div>
						</div>

						<Gap height='26px' />

						<ShipmentInfoCargoSummary />
					</div>
				</div>
			</div>

			<Gap height='24px' />

			<div className=' relative grow flex flex-col overflow-hidden'>
				<div className='grow-0 flex items-baseline gap-[12px]'>
					<div className='grow-0 h3-b text-black whitespace-nowrap'>
						{quoteRates?.length ?? 0} {t(`Quotes Found`)}
					</div>
					{!quoteRateCompleted && (
						<div className='flex gap-[4px] text-grey-03'>
							{t(`Loading rates`)}
							<LoadingOutlined />
						</div>
					)}
					{cfsContext.calcIsLiftGateRequired() ? (
						<span className='text-grey-03'>
							{t(
								`The size or weight of your shipment exceeds the maximum limit of the Liftgate service. The service provider may not be able to provide the service or charge additional fees.`,
							)}
						</span>
					) : null}
				</div>
				<Gap height='16px' />
				<div className='grow overflow-y-scroll'>
					<Table
						columns={[
							{
								title: t(`Carrier`),
								width: 'minmax(auto, 1.5fr)',
								dataIndex: 'carrier_name',
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id; // ? id 和 quote_id
									const selectedClass = isSelected ? `bg-primary-light-05` : '';

									const hasError = record.errors.length > 0;

									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} flex items-center gap-[18px] body-3-r text-black relative ${
												hasError ? 'pb-[45px]' : ''
											} `}
											onClick={() => onRowClick(record)}
										>
											<CarrierCol record={record} isSelected={isSelected} value={value} />
											{/* warning */}
											{hasError && (
												<Alert
													className=' box-border absolute bottom-0 h-[24px] w-[calc(100vw_-_240px_-_48px_-_48px_-_33px)] border-none text-xs'
													message={`Carrier warnings: ${record.errors.join(' | ')}`}
													type='warning'
													showIcon
												/>
											)}
										</div>
									);
								}),
							},
							{
								title: t(`Rating`),
								dataIndex: 'carrier_score',
								key: 'carrier_score',
								sorter: (a: any, b: any, sortOrder?: TSortOrder) => {
									const scoreA = a.carrier_score ? parseFloat(a.carrier_score) : 0;
									const scoreB = b.carrier_score ? parseFloat(b.carrier_score) : 0;
									if (sortOrder == null) {
										return 0;
									}
									if (sortOrder === 'ascend') {
										return scoreA - scoreB;
									}
									return scoreB - scoreA;
								},
								HeaderRender: ({ title, style, className, onSort, sortOrder }) => (
									<div
										style={style}
										className={`${className} ${onSort ? 'flex items-center cursor-pointer' : ''}`}
										onClick={() => onSort?.()}
									>
										{title}
										<Tooltip
											title={t(
												'The scores of carriers are composed of four aspects: pick-up efficiency, delivery efficiency, Rebill and tracking timeliness. Please choose the appropriate carriers according to your own needs.',
											)}
										>
											<InfoCircleOutlined className='ml-1' />
										</Tooltip>
										<span className='flex flex-col items-center ml-2'>
											<CaretUpOutlined
												className={`text-xs cursor-pointer -mb-0.5 ${sortOrder === 'ascend' ? 'text-primary-regular' : ''}`}
											></CaretUpOutlined>
											<CaretDownOutlined
												className={`text-xs cursor-pointer -mt-0.5 ${sortOrder === 'descend' ? 'text-primary-regular' : ''}`}
											></CaretDownOutlined>
										</span>
									</div>
								),
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
									const selectedClass = isSelected ? `bg-primary-light-05` : '';
									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} h3-sb text-black`}
											onClick={() => onRowClick(record)}
										>
											<Rate value={value} />
										</div>
									);
								}),
							},
							{
								title: t(`Price`),
								dataIndex: 'rate',
								key: 'rate',
								sorter: (a: any, b: any, sortOrder?: TSortOrder) => {
									const priceA = a.rate ? parseFloat(a.rate) : 0;
									const priceB = b.rate ? parseFloat(b.rate) : 0;
									if (sortOrder == null) {
										return 0;
									}
									if (sortOrder === 'ascend') {
										return priceA - priceB;
									}
									return priceB - priceA;
								},
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
									const selectedClass = isSelected ? `bg-primary-light-05` : '';

									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} h3-sb text-black`}
											onClick={() => onRowClick(record)}
										>
											<div>
												{`$${value}`}
												{!!record.recommend_rate &&
													record.recommend_rate !== '' &&
													record.recommend_rate !== value && (
														<>
															<br />
															<span className='body-3-sb text-grey-01 line-through'>{`$${record.recommend_rate}`}</span>
														</>
													)}
											</div>
										</div>
									);
								}),
							},
							{
								title: t(`Transit Time`),
								dataIndex: 'transit_days',
								key: 'transit_days',
								sorter: (a: any, b: any, sortOrder?: TSortOrder) => {
									const scoreA = a.carrier_score ? parseFloat(a.carrier_score) : 0;
									const scoreB = b.carrier_score ? parseFloat(b.carrier_score) : 0;
									const transitDaysA = a.transit_days ?? 0;
									const transitDaysB = b.transit_days ?? 0;
									if (sortOrder == null) {
										return 0;
									}
									if (sortOrder === 'ascend') {
										return transitDaysA - transitDaysB || scoreB - scoreA;
									}
									return transitDaysB - transitDaysA || scoreB - scoreA;
								},
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
									const selectedClass = isSelected ? `bg-primary-light-05` : '';

									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} body-3-sb text-black`}
											onClick={() => onRowClick(record)}
										>
											{record.transit_days === record.transit_days_max
												? `${record.transit_days ?? '--'} ${t(`business days`)}`
												: `${record.transit_days ?? '--'}~${record.transit_days_max ?? '--'} ${t(
														`business days`,
													)}`}
										</div>
									);
								}),
							},
							{
								title: t(`Est.Delivery`),
								dataIndex: 'estimated_delivery_date',
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
									const selectedClass = isSelected ? `bg-primary-light-05` : '';

									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} body-3-r text-black`}
											onClick={() => onRowClick(record)}
										>
											{record.estimated_delivery_date === record.estimated_delivery_date_max
												? dayjs(record.estimated_delivery_date).format('MMM D')
												: `${dayjs(record.estimated_delivery_date).format('MMM D')} ~ ${dayjs(
														record.estimated_delivery_date_max,
													).format('MMM D')}`}
										</div>
									);
								}),
							},
							// {
							// 	title: t(`Max.Liability`),
							// 	dataIndex: 'max',
							// 	CellRender: observer(({ record, style, className, onRowClick }) => {
							// 		const isSelected =
							// 			!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
							// 		const selectedClass = isSelected ? `bg-primary-light-05` : '';

							// 		const Container = ({ title, value }: { title: string; value: string }) => (
							// 			<>
							// 				<div className='body-3-m'>{title}</div>
							// 				<div className='body-3-r'>{`US $ ${value ?? ''}`}</div>
							// 			</>
							// 		);
							// 		return (
							// 			<div
							// 				style={{ ...style, alignItems: 'unset' }}
							// 				className={`${className} ${selectedClass} flex flex-col text-black`}
							// 				onClick={() => onRowClick(record)}
							// 			>
							// 				<div className='grid grid-cols-[40px_auto] grid-rows-2 gap-x-[18px] gap-y-[8px] whitespace-nowrap'>
							// 					<Container title={t(`New`)} value={record['maxNew']} />

							// 					<Container title={t(`Used`)} value={record['maxUsed']} />
							// 				</div>
							// 			</div>
							// 		);
							// 	}),
							// },
							{
								title: t(`Quote Expired On`),
								dataIndex: 'expiration_date',
								CellRender: observer(({ value, record, style, className, onRowClick }) => {
									const isSelected =
										!!cfsContext.selectedQuoteRate && cfsContext.selectedQuoteRate.id === record.id;
									const selectedClass = isSelected ? `bg-primary-light-05` : '';

									return (
										<div
											style={{ ...style }}
											className={`${className} ${selectedClass} body-3-r text-black`}
											onClick={() => onRowClick(record)}
										>
											{dayjs(value).format('MMM D')}
										</div>
									);
								}),
							},
						]}
						dataSource={quoteRates ?? []}
						commonHeaderClass='sticky z-[500] top-0 pb-[15px] body-3-r text-grey-01 bg-white'
						commonBodyClass='p-[15px_15px_15px_0] flex items-center'
						commonCellStyle={{
							borderBottom: '1px solid #E7E7E7',
						}}
						onRowClick={(record) => {
							cfsContext.selectedQuoteRate = record;
						}}
					/>
				</div>
			</div>
			{contextHolder}
		</>
	);
});

export const StepTwo = observer(() => {
	const [form] = Form.useForm();

	return (
		<Form
			form={form}
			className='box-border p-[48px] w-full h-full flex flex-col overflow-x-hidden overflow-y-scroll step-form'
			onValuesChange={(changedValues, allValues) => {
				// _.merge(cfsContext.stepOneFormValues, changedValues);
			}}
		>
			<StepTwoForm form={form} />
		</Form>
	);
});
