import { createContext, useContext } from 'react';
import { cfs } from './cfs.context';

export const cfsContext = createContext(cfs);

export const CFSContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <cfsContext.Provider value={cfs}>{children}</cfsContext.Provider>;
};

export const useCFSContext = () => useContext(cfsContext);
