import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCFSQuoteProps, GetCFSQuoteRes } from './interface';

export * from './interface';

export const getCFSQuote = async (props: GetCFSQuoteProps) => {
	return await axios.get<GetCFSQuoteRes>(`/cfs/quotes/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
