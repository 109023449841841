import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import Exchange24pxIcon from '@/icons/exchange-24px-icon';
import { CFSAddressType } from '@/interfaces/cfs-address-type.enum';
import { destinationAccesorials, pickupAccesorials } from '@/interfaces/cfs-quote.interface';
import { useCFSContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { formItemWrapper } from '../../form-item-wrapper';
import { AddressTypeRadio } from './address-type-radio';
import { DeliveryStatement } from './delivery-statement';
import { DeselectLGModal } from './deselect-lg-modal';
import { ZipCodeSelect } from './zip-code-select';
import { CFSCodeSelect } from './cfs-code-select';

interface ZipCodePickerProps {
	errors: {
		pickup: {
			isError: boolean;
			reason: string;
		};
		destination: {
			isError: boolean;
			reason: string;
		};
	};
}

const OptionRender = ({ value, label }: { value: string; label: string | React.ReactNode }) => {
	const { t } = useTranslation();

	const isPopular = ['appointment', 'liftgate', 'residential'].includes(value);

	if (isPopular) {
		return (
			<div className='w-full flex gap-[4px]'>
				<span>{label}</span>
				<Tag color='blue'>{t(`Popular`)}</Tag>
			</div>
		);
	}
	return <div>{label}</div>;
};

export const ZipCodePicker = observer(({ errors }: ZipCodePickerProps) => {
	const { t } = useTranslation();

	const cfsContext = useCFSContext();

	const [deselectLG, setDeselectLG] = useState<'pickup' | 'delivery' | 'none'>('none');

	return (
		<div
			style={{
				gridTemplateColumns: 'minmax(24px, 24px) minmax(528px, 528px) minmax(528px, 528px)',
			}}
			className='w-full grid items-center gap-x-[36px]'
		>
			{/* row 1 */}
			<CircleBlueFillIcon />
			<CFSCodeSelect errored={errors.pickup.isError} />
			<Form.Item name='pickup_accessorials' className='grow shrink w-[480px] overflow-hidden'>
				<Select
					placeholder={t(`Add`) + ' ' + t(`pickup accessorials`)}
					mode='multiple'
					maxTagCount='responsive'
					suffixIcon={null}
					variant='borderless'
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} w-full h-[42px]`}
					options={pickupAccesorials.map((i) => ({
						label: t(i.name),
						value: i.key,
					}))}
					optionRender={(option) => (
						<OptionRender value={option.value as string} label={option.label} />
					)}
				/>
			</Form.Item>
			{/* gap */}
			<Gap height='12px' />
			<Gap height='12px' />
			<Gap height='12px' />
			{/* row 2 */}
			<Exchange24pxIcon className='z-10 cursor-pointer translate-x-[1px] opacity-0' />
			<div className='w-510px'></div>
			<div></div>
			{/* gap */}
			<Gap height='24px' />
			<Gap height='24px' />
			<Gap height='24px' />
			{/* row 4 */}
			<DestinationIcon />
			<div className='flex gap-[4px] w-[528px]'>
				<ZipCodeSelect
					placeholder={t('Destination City') + ' / ' + t(`Zipcode`) + ' / ' + t(`Address`)}
					value={
						cfsContext.destinationAddress
							? cfsContext.destinationAddress.id !== -1
								? {
										label: cfsContext.destinationAddress?.name ?? '',
										value: `warehouse-${cfsContext.destinationAddress.id}`,
									}
								: {
										label: cfsContext.destinationAddress?.city?.full_name_zipcode ?? '',
										value: `city-${cfsContext.destinationAddress.city.id}`,
									}
							: undefined
					}
					style={{
						...formItemWrapper.style,
						...(errors.destination.isError ? { borderColor: '#ff4d4f' } : {}),
					}}
					className={`${formItemWrapper.className} grow h-[42px]`}
					onSelect={(address) => {
						// @ts-expect-error types not match
						cfsContext.destinationAddress = address;
					}}
				/>
				<DeliveryStatement />
			</div>
			<Form.Item name='destination_accessorials' className='grow shrink w-[480px] overflow-hidden'>
				<Select
					placeholder={t(`Add`) + ' ' + t(`destination accessorials`)}
					mode='multiple'
					maxTagCount='responsive'
					suffixIcon={null}
					variant='borderless'
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} w-full  h-[42px]`}
					options={destinationAccesorials.map((i) => ({
						label: t(i.name),
						value: i.key,
					}))}
					optionRender={(option) => (
						<OptionRender value={option.value as string} label={option.label} />
					)}
					onDeselect={(value) => {
						if (
							value === 'liftgate' &&
							cfsContext.destinationAddressType === CFSAddressType.RESIDENTIAL
						) {
							setDeselectLG('delivery');
						}
					}}
				/>
			</Form.Item>
			{/* gap */}
			<Gap height='12px' />
			<Gap height='12px' />
			<Gap height='12px' />
			{/* row 5 */}
			<div></div>
			<AddressTypeRadio
				value={cfsContext.destinationAddressType}
				classNames='w-[510px]'
				onChange={(val) => {
					cfsContext.destinationAddressType = val;

					/**
					 * 2. 如果Delivery的address type 选择为Residential，则增值服务中的Liftgate Delivery自动勾选，且不允许用户手动取消。
					 * 3. 如果Delivery的address type 选择为Trade Show，则增值服务中的Liftgate Delivery自动勾选，允许取消。
					 * 4. 如果先选择了Residential或Trade Show，又把地址切换为其他，则Liftgate Delivery不自动取消，但是允许手动取消。
					 */
					if (
						(val === CFSAddressType.RESIDENTIAL || val === CFSAddressType.TRADESHOW) &&
						!cfsContext.stepOneFormValues.destination_accessorials.includes('liftgate')
					) {
						cfsContext.stepOneFormValues.destination_accessorials = [
							...cfsContext.stepOneFormValues.destination_accessorials,
							'liftgate',
						];
					}
				}}
			/>
			<div></div>

			<DeselectLGModal
				width={'600px'}
				open={deselectLG !== 'none'}
				onOk={() => {
					!cfsContext.stepOneFormValues.destination_accessorials.includes('liftgate') &&
						(cfsContext.stepOneFormValues.destination_accessorials = [
							...cfsContext.stepOneFormValues.destination_accessorials,
							'liftgate',
						]);
					setDeselectLG('none');
				}}
				onCancel={() => setDeselectLG('none')}
			/>
		</div>
	);
});
