import {
	getCFSAIAutofill,
	getCFSAIAutofillForShipment,
	getCFSAIAutofillFromPalletCalculator,
} from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetCFSAutofill = (options?: Record<any, any>) => {
	const res = useRequest(getCFSAIAutofill, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};

export const useGetCFSAutofillForShipment = (options?: Record<any, any>) => {
	const res = useRequest(getCFSAIAutofillForShipment, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};

export const useGetCFSAutofillFromPalletCalculator = (options?: Record<any, any>) => {
	const res = useRequest(getCFSAIAutofillFromPalletCalculator, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};
