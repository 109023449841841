import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadCFSFileProps } from './interface';

export const downloadCFSFile = async (
	props: DownloadCFSFileProps,
	config?: { preview?: boolean },
) => {
	const { documentId, shipmentId, fileName } = props;
	const url = baseURL + `newDocuments/${documentId}/cfs_shipment/${shipmentId}`;

	downloadFactory(url, fileName, { preview: config?.preview });
};
