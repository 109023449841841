import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Table, TableProps } from 'antd';
import { businessStatusMap, CFSShipment } from '@/interfaces/cfs-shipment.interface';
import '@/styles/no-cell-border-table.less';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { StatusLabel } from '../ftl-orders/status-label';
import { LTLFTLFilter } from '../ltl-orders/ltl-ftl-filter';
import useCFSOrdersParams, { CFSOrdersQuery } from './use-cfs-orders-params';
import { useState } from 'react';
import { ExportModal, ViewDraftOrdersButton } from './components';
import { CFSSearchRate } from '../cfs-search-rate';

const CFSOrders = observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useCFSOrdersParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<CFSOrdersQuery, 'customer_status' | 'last_days' | 'query' | 'sort_by' | 'sort_value'>
	>({
		customer_status: parsedSearchParams.customer_status ?? [],
		last_days: parsedSearchParams.last_days ?? '30',
		query: parsedSearchParams.query ?? '',
		sort_by: parsedSearchParams.sort_by ?? '',
		sort_value: parsedSearchParams.sort_value ?? 'desc',
	});

	const {
		runAsync: getShipmentList,
		data: shipmentList,
		loading: shipmentListLoading,
		pagination,
	} = mode == 'agent'
		? // TODO 替换成 CFS 版本
			apiHooks.useGetAgentShipmentList(
				{
					refreshDeps: [queriesWithoutPagination],
					refreshDepsAction: () => {
						void getShipmentList(
							{ current: 1, pageSize },
							{
								params: {
									...queriesWithoutPagination,
									customer_status: (queriesWithoutPagination.customer_status ?? [])
										.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
										.join(','),
									last_days:
										queriesWithoutPagination.last_days == 'all'
											? ''
											: queriesWithoutPagination.last_days,
								},
							},
						);
					},
				},
				'cfs',
			)
		: apiHooks.useGetCFSShipmentList({
				refreshDeps: [queriesWithoutPagination],
				refreshDepsAction: () => {
					void getShipmentList(
						{ current: 1, pageSize },
						{
							params: {
								...queriesWithoutPagination,
								customer_status: (queriesWithoutPagination.customer_status ?? [])
									.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
									.join(','),
								last_days:
									queriesWithoutPagination.last_days == 'all'
										? ''
										: queriesWithoutPagination.last_days,
							},
						},
					);
				},
			});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<CFSShipment>['columns'] = [
		{
			title: t(`Order & Reference`),
			dataIndex: 'uid',
			width: 180,
			fixed: 'left',
			render: (value, record) => (
				<div>
					<div>{value}</div>
					<div className='text-grey-01'>{record.customer_reference_number ?? ''}</div>
				</div>
			),
		},
		{
			title: t('User'),
			dataIndex: ['user', 'name'],
			width: 120,
		},
		{
			title: t(`Carrier & Pro Number`),
			dataIndex: 'vendor_carrier_name',
			width: 180,
			render: (_, record) => (
				<div>
					<div>{record.vendor_carrier_name}</div>
					<div className='text-grey-01'>{record.pro_number ? `PRO ${record.pro_number}` : ''}</div>
				</div>
			),
		},
		{
			title: t(`Status`),
			dataIndex: 'customer_status',
			width: 140,
			render: (value, record) => (
				<StatusLabel
					status={value}
					text={businessStatusMap[value]}
					type={record.reject_type}
					reason={record.reject_reason}
				/>
			),
		},
		{
			title: t(`Delivery Date`),
			dataIndex: 'actual_delivery_date',
			width: 140,
			render: (_, record) =>
				dayjs(record.actual_delivery_date ?? record.estimated_delivery_date).format('YYYY-MM-DD'),
		},
		{
			title: t(`Pickup City`),
			dataIndex: 'pickup_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.pickup_city.name}</div>
					<div className='text-grey-01'>{`${record.pickup_city.state} - ${record.pickup_zipcode}`}</div>
					<div className='text-grey-01'>{record.pickup_cfs_location_firms_code}</div>
				</div>
			),
		},
		{
			title: t(`Destination`),
			dataIndex: 'destination_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.destination_city.name}</div>
					<div className='text-grey-01'>{`${record.destination_city.state} - ${record.destination_zipcode}`}</div>
				</div>
			),
		},
		{
			title: t('Base Price'),
			dataIndex: 'sell_rate',
			width: 120,
			render: (value) => `$${value}`,
		},
		{
			title: t('Premium'),
			dataIndex: 'premium',
			width: 120,
			render: (value) => `$${value}`,
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (_, record) => (
				<Button type='link' className='px-0' onClick={() => navigate(`/cfs-orders/${record.uid}`)}>
					{t(`View`)}
				</Button>
			),
		},
	];

	const _columns = columns.filter((column) => !(mode != 'agent' && column.title === t('User')));

	const [selectedRows, setSelectedRows] = useState<CFSShipment[]>([]);
	const rowSelection: TableProps<CFSShipment>['rowSelection'] = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
		},
	};

	const [isExportModalOpen, setIsExportModalOpen] = useState(false);
	const handleExport = () => {
		setIsExportModalOpen(true);
	};
	const handleExportModalOk = () => {
		setIsExportModalOpen(false);
	};
	const handleExportModalCancel = () => {
		setIsExportModalOpen(false);
	};

	const handleChange = useMemoizedFn((newQueries: CFSOrdersQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'query', 'customer_status', 'last_days'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(() => {
		void (async () => {
			await getShipmentList(
				{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
				{
					params: {
						...queriesWithoutPagination,
						customer_status: (queriesWithoutPagination.customer_status ?? []).join(','),
						last_days:
							queriesWithoutPagination.last_days == 'all' ? '' : queriesWithoutPagination.last_days,
					},
				},
			);
			changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
		})();
	});

	if (parsedSearchParams.draft_shipment_id) {
		return <CFSSearchRate />;
	}

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>
					{mode == 'agent' ? t(`CFS Orders`) : t(`My CFS Orders`)}
				</div>
				{shipmentListLoading && <LoadingOutlined />}
				<div className='flex-auto'></div>
				<ViewDraftOrdersButton />
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px]'>
				<LTLFTLFilter
					value={{
						query: queriesWithoutPagination.query,
						last_days: queriesWithoutPagination.last_days,
						customer_status: queriesWithoutPagination.customer_status,
					}}
					statusFieldName='customer_status'
					type='order'
					statusOptions={Object.entries(businessStatusMap)
						.filter(([value, label]) => value !== '60')
						.map(([value, label]) => ({ label: t(label), value: `${value}` }))}
					enableLastDays={true}
					handleChange={(value) => handleChange(value)}
					extra={
						<Button type='link' icon={<DownloadOutlined />} onClick={handleExport}>
							<span className='body-3-m text-primary-regular'>{t('Export')}</span>
						</Button>
					}
					searchPlaceholder={t(`Search Zipcode, Order #, Reference #, FIRMS code`)}
				/>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					rowSelection={rowSelection}
					className='no-cell-border-table'
					rowKey='id'
					columns={_columns}
					dataSource={shipmentList}
					bordered
					pagination={false}
					scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 345px)' }}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>

			<ExportModal
				open={isExportModalOpen}
				onOk={handleExportModalOk}
				onCancel={handleExportModalCancel}
				selected={selectedRows}
				total={total}
				queries={queriesWithoutPagination}
			/>
		</div>
	);
});

export default CFSOrders;
