import { ButtonProps } from 'antd';
import * as _ from 'lodash';
import { CFSAddressType } from '@/interfaces/cfs-address-type.enum';
import { Quote } from '@/interfaces/cfs-quote.interface';
import { CFSShipmentItem } from '@/interfaces/cfs-shipment.interface';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { Contact } from '@/interfaces/contact.interface';
import {
	CreateCFSQuotePropsData,
	CreateCFSShipmentRes,
	getItemFreightClass,
	CFSQuoteRate,
} from '@/services/apis';
import { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import calcPremium from '@/utils/calc-premium';
import { CFSLocation } from '@/interfaces/cfs-location.interface';

const initialStepOneFormValuesItem = {
	description: undefined, // 商品描述
	class: undefined, // 货运等级
	units: undefined, // 单位数量
	total_weight: undefined, // 总重量
	weight_unit: 'lbs', // 重量单位
	dimension_unit: 'in', // 尺寸单位
	package_type: 'Pallet', // 包装类型
	pieces: undefined, // 件数
	height: undefined, // 高度
	length: undefined, // 长度
	width: undefined, // 宽度
	nmfc: undefined, // NMFC 代码
	stackable: false, // 是否可堆叠
} as const;

const calcItemFreightClassRequiredFields = [
	'dimension_unit',
	'units',
	'width',
	'length',
	'height',
	'total_weight',
	'weight_unit',
];

export const kgToLb = (kg: number, ceil = false) => {
	const result = kg / 0.4535;
	return ceil ? Math.ceil(result) : result;
};

export const cm2in = (cm: number, ceil = false) => {
	const result = cm * 0.393701;
	return ceil ? Math.ceil(result) : result;
};

export const cm3ToFt3 = (cm3: number, ceil = false) => {
	const result = cm3 * 0.00003531;
	return ceil ? Math.ceil(result) : result;
};

export const in3ToFt3 = (in3: number, ceil = false) => {
	const result = in3 * 0.0005787;
	return ceil ? Math.ceil(result) : result;
};

export const freightClasses = [
	{
		threshold: 30,
		classCode: '60',
	},
	{
		threshold: 22.5,
		classCode: '65',
	},
	{
		threshold: 15,
		classCode: '70',
	},
	{
		threshold: 12,
		classCode: '85',
	},
	{
		threshold: 10,
		classCode: '92.5',
	},
	{
		threshold: 8,
		classCode: '100',
	},
	{
		threshold: 6,
		classCode: '125',
	},
	{
		threshold: 4,
		classCode: '175',
	},
	{
		threshold: 2,
		classCode: '250',
	},
	{ threshold: 1, classCode: '300' },
	{
		threshold: 0,
		classCode: '400',
	},
];

export const imperialFreightClass = (lb: number, ft3: number) => {
	const density = lb / ft3;
	for (let i = 0; i < freightClasses.length; i++) {
		if (density >= freightClasses[i].threshold) {
			return freightClasses[i].classCode;
		}
	}

	return '400';
};

export const calcTotalWeight = (items: CFSShipmentItem[]) => {
	const totalWeight = _.sum(
		items?.map((item) =>
			item.weight_unit === 'lbs'
				? Number(item.total_weight)
				: kgToLb(Number(item.total_weight), true),
		),
	);
	return Number.isNaN(totalWeight) ? 0 : Math.ceil(totalWeight);
};

export const calcTotalVolume = (items: CFSShipmentItem[]) => {
	const sum = _.sum(
		items?.map((item) => {
			const length = item.dimension_unit === 'in' ? item.length : cm2in(item.length, true);
			const width = item.dimension_unit === 'in' ? item.width : cm2in(item.width, true);
			const height = item.dimension_unit === 'in' ? item.height : cm2in(item.height, true);
			return in3ToFt3(item.units * height * width * length);
		}),
	);

	return Number.isNaN(sum) ? 0 : sum.toFixed(2);
};

export const generateNumberOfUnits = (items: CFSShipmentItem[]) => {
	const map: Record<string, number> = {};

	for (const item of items) {
		if (item.units === undefined) {
			continue;
		}
		if (map[item.package_type]) {
			map[item.package_type] += item.units;
		} else {
			map[item.package_type] = item.units;
		}
	}

	return (
		Object.entries(map)
			?.map(([k, v]) => `${v} ${k}`)
			.join(', ') ?? ''
	);
};

class CFSContext {
	private _step: 1 | 2 | 3 | 4;
	private _pickupLocation: CFSLocation;
	private _pickupSpecialRequest: string;

	private _stepNextHandler: () => void;

	private _stepNextButtonProps: ButtonProps = {};

	private _stepOneFormValues: Omit<
		CreateCFSQuotePropsData,
		| 'items'
		| 'pickup_date'
		| 'destination_zipcode'
		| 'destination_city_id'
		| 'destination_address_type'
	> & {
		items: Array<CFSShipmentItem & { id: string }>;
		pickup_date: Dayjs;
	} = {
		pickup_date: undefined, // 提货日期
		items: [{ ...CFSContext.createStepOneFormValuesItem() }],
		pickup_accessorials: [], // 提货附加服务
		destination_accessorials: [], // 目的地附加服务
		shipment_accessorials: [], // 运输附加服务
		pickup_cfs_location_id: undefined,
	};

	private _stepThreeFormValues: Partial<{
		destination_open_time: Dayjs;
		destination_close_time: Dayjs;
		estimated_delivery_date: Dayjs;
		customer_reference_number: string; // required
		mbl_number: string;
		hbl_number: string;
		dropoff_number: string; // required
		dropoff_instruction: string; // required
		special_request?: string; // optional

		is_insurance_entrusted: boolean;
		cargo_value: number | undefined;
	}> & { isCheckAcknowledge: boolean } = {
		destination_open_time: undefined,
		destination_close_time: undefined,
		estimated_delivery_date: undefined,
		customer_reference_number: undefined, // required
		dropoff_number: undefined, // required
		dropoff_instruction: undefined, // required
		special_request: undefined, // optional

		isCheckAcknowledge: false,

		is_insurance_entrusted: true,
		cargo_value: undefined,
	};

	private _selectedQuoteRate?: CFSQuoteRate;

	private _quote?: Quote;

	private _order?: CreateCFSShipmentRes['data'];

	private _destinationAddress?: Omit<Warehouse, 'contacts'> & {
		address2: string;
		destination_special_request?: string;
		contacts: (Contact & { phone_ext?: string })[];
	};

	private _saveDestinationAddress = false;

	private _quoteRates: CFSQuoteRate[] = [];

	private _destinationAddressType: CFSAddressType = CFSAddressType.BUSINESS;

	constructor() {
		makeAutoObservable(this);

		this.init();
		// 防抖减少请求数量
		this.calcItemFreightClass = _.debounce(this.calcItemFreightClass.bind(this), 500);
	}

	get step() {
		return this._step;
	}

	set step(value: typeof this._step) {
		runInAction(() => {
			this._step = value;
		});
	}

	get stepNextHandler() {
		return this._stepNextHandler;
	}

	set stepNextHandler(value: typeof this._stepNextHandler) {
		runInAction(() => {
			this._stepNextHandler = value;
		});
	}

	get stepNextButtonProps() {
		return this._stepNextButtonProps;
	}

	set stepNextButtonProps(value: typeof this._stepNextButtonProps) {
		runInAction(() => {
			this._stepNextButtonProps = value;
		});
	}

	get stepOneFormValues() {
		return this._stepOneFormValues;
	}

	set stepOneFormValues(value: typeof this._stepOneFormValues) {
		// 处理旧数据
		if (value.pickup_accessorials === null) {
			value.pickup_accessorials = [];
		}

		if (value.destination_accessorials === null) {
			value.destination_accessorials = [];
		}

		if (value.shipment_accessorials === null) {
			value.shipment_accessorials = [];
		}

		runInAction(() => {
			this._stepOneFormValues = value;
		});
	}

	get stepThreeFormValues() {
		return this._stepThreeFormValues;
	}

	set stepThreeFormValues(value: typeof this._stepThreeFormValues) {
		runInAction(() => {
			this._stepThreeFormValues = value;
		});
	}

	get selectedQuoteRate() {
		return this._selectedQuoteRate;
	}

	set selectedQuoteRate(value: typeof this._selectedQuoteRate) {
		runInAction(() => {
			this._selectedQuoteRate = value;
		});
	}

	get quote() {
		return this._quote;
	}

	set quote(value: typeof this._quote) {
		runInAction(() => {
			this._quote = value;
		});
	}

	get order() {
		return this._order;
	}

	set order(value: typeof this._order) {
		runInAction(() => {
			this._order = value;
		});
	}

	get destinationAddress() {
		return this._destinationAddress;
	}

	set destinationAddress(value: typeof this._destinationAddress) {
		runInAction(() => {
			this._destinationAddress = value;
		});
	}

	get saveDestinationAddress() {
		return this._saveDestinationAddress;
	}

	set saveDestinationAddress(value: typeof this._saveDestinationAddress) {
		runInAction(() => {
			this._saveDestinationAddress = value;
		});
	}

	get quoteRates() {
		return this._quoteRates;
	}

	set quoteRates(value: typeof this._quoteRates) {
		runInAction(() => {
			this._quoteRates = value;
		});
	}

	static createStepOneFormValuesItem() {
		return { ...initialStepOneFormValuesItem, id: Math.random().toString() };
	}

	get destinationAddressType() {
		return this._destinationAddressType;
	}

	set destinationAddressType(value: typeof this._destinationAddressType) {
		runInAction(() => {
			this._destinationAddressType = value;
		});
	}

	get premium() {
		return calcPremium(this.stepThreeFormValues.cargo_value);
	}

	get totalPrice() {
		return this.selectedQuoteRate?.rate
			? Number((Number(this.selectedQuoteRate.rate) + this.premium).toFixed(2))
			: 0;
	}

	get pickupLocation() {
		return this._pickupLocation;
	}

	set pickupLocation(value: typeof this._pickupLocation) {
		runInAction(() => {
			this._pickupLocation = value;
		});
	}

	get pickupSpecialRequest() {
		return this._pickupSpecialRequest;
	}

	set pickupSpecialRequest(value: typeof this._pickupSpecialRequest) {
		runInAction(() => {
			this._pickupSpecialRequest = value;
		});
	}

	init() {
		this.step = 1;
		this.stepNextButtonProps = {};
		this.stepOneFormValues = {
			pickup_date: undefined, // 提货日期
			items: [{ ...CFSContext.createStepOneFormValuesItem() }],
			pickup_accessorials: [], // 提货附加服务
			destination_accessorials: [], // 目的地附加服务
			shipment_accessorials: [], // 运输附加服务
			pickup_cfs_location_id: undefined,
		};
		this.stepThreeFormValues = {
			destination_open_time: undefined,
			destination_close_time: undefined,
			estimated_delivery_date: undefined,
			customer_reference_number: undefined, // required
			mbl_number: undefined,
			hbl_number: undefined,
			dropoff_number: undefined, // required
			dropoff_instruction: undefined, // required
			special_request: undefined, // optional

			isCheckAcknowledge: false,
			is_insurance_entrusted: true,
			cargo_value: undefined,
		};
		this.selectedQuoteRate = undefined;
		this.quote = undefined;
		this.order = undefined;
		this.pickupLocation = undefined;
		this.destinationAddress = undefined;
		this.saveDestinationAddress = false;
		this.quoteRates = [];
		this.destinationAddressType = CFSAddressType.BUSINESS;
		this._pickupSpecialRequest = undefined;
	}

	addStepOneFormValuesItem() {
		runInAction(() => {
			this.stepOneFormValues.items = [
				...this.stepOneFormValues.items,
				CFSContext.createStepOneFormValuesItem(),
			];
		});
	}

	calcItemFreightClass() {
		const items = [...this.stepOneFormValues.items];
		void Promise.all(
			items.map((item) => {
				if (calcItemFreightClassRequiredFields.some((field) => !item[field])) {
					return Promise.resolve({ data: { class: undefined } });
				}
				return getItemFreightClass({ params: _.pick(item, calcItemFreightClassRequiredFields) });
			}),
		).then((responses) => {
			runInAction(() => {
				this.stepOneFormValues.items = this.stepOneFormValues.items.map((item) => ({
					...item,
					class: responses.find((r, index) => items[index].id === item.id)?.data?.class?.toString(),
				}));
			});
		});
	}

	calcIsLiftGateRequired() {
		return (
			this.stepOneFormValues.destination_accessorials.includes('liftgate') &&
			this.stepOneFormValues.items.some((item) => {
				const isTooLong =
					item.dimension_unit === 'cm'
						? Math.max(item.length, item.width) > 203 ||
							Math.min(item.length, item.width) > 127 ||
							item.height > 228
						: Math.max(item.length, item.width) > 80 ||
							Math.min(item.length, item.width) > 50 ||
							item.height > 90;
				const isOverweight =
					item.weight_unit === 'kg'
						? Math.ceil(item.total_weight / item.units) > 1360
						: Math.ceil(item.total_weight / item.units) > 3000;
				return isTooLong || isOverweight;
			})
		);
	}

	// https://ue2fl6z2dmj.larksuite.com/wiki/VmrAwvY0vi6kSckZhwuuq80Bseh?from=from_copylink
	isSizeOrWeightLimitationsExceeded() {
		// A
		const isDestinationResidential = this.destinationAddressType === CFSAddressType.RESIDENTIAL;
		const isDestinationLimitedAccess =
			this.destinationAddressType === CFSAddressType.LIMITED_ACCESS;
		const isDestinationLiftgate =
			this.stepOneFormValues.destination_accessorials.includes('liftgate');

		// C
		if (!isDestinationResidential && !isDestinationLimitedAccess && !isDestinationLiftgate) {
			const totalWeight = calcTotalWeight(this.stepOneFormValues.items);
			return totalWeight > 30000;
		}

		const items = this.stepOneFormValues.items.map((item) => ({
			units: item.units,
			length: item.dimension_unit === 'in' ? item.length : cm2in(item.length, true),
			width: item.dimension_unit === 'in' ? item.width : cm2in(item.width, true),
		}));

		// B.1
		const standardCoefficient = 2;
		const getNonstandardCoefficient = (length: number, width: number) =>
			((length / 12) * (width / 12)) / 8;
		const linearFeet = items.reduce((acc, item) => {
			const isStandard =
				(item.length === 48 && item.width === 40) || (item.length === 40 && item.width === 48);
			return (
				acc +
				item.units *
					(isStandard ? standardCoefficient : getNonstandardCoefficient(item.length, item.width))
			);
		}, 0);
		if (linearFeet > 24) {
			return true;
		}

		// B.2
		const totalProjectedArea = items.reduce(
			(acc, item) => acc + (item.units * item.length * item.width) / 144,
			0,
		);
		if (totalProjectedArea > 160) {
			return true;
		}

		// B.3
		const totalWeight = calcTotalWeight(this.stepOneFormValues.items);
		if (totalWeight > 10000) {
			return true;
		}

		return false;
	}
}

export const cfs = new CFSContext();
